import React from "react";
import { GoTrash } from "react-icons/go";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { useScreenSize } from "../../../utils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import style from "./ColorCard.module.css";

const ColorCardHexaTool = ({
  color,
  sortable,
  setSelectedColor,
}) => {
  const { setHexaColormatch } = useAppContext();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      animateLayoutChanges: () => false,
      id: color.id,
    });

    console.log(color.id)

  const screenSize = useScreenSize();


  const handleDeleteColor = (deleteColor) => {
    setHexaColormatch((prevState) =>
      prevState.filter((color) => color !== deleteColor)
    );
    setSelectedColor("");
  };

  const cardStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  let sortableAttr;

  if (sortable) {
    sortableAttr = { ...attributes };
  }

  return (
    <div
      {...sortableAttr}
      {...listeners}
      ref={setNodeRef}
      style={cardStyle}
      className={screenSize < 992 && "px-2"}
    >
      <Card
        style={{
          borderRadius: 0,
          border: "none",
        }}
        className={sortable && style.animatedCard}
      >
        <div
          className={`${style.imgContainer} ${style.fallbackAspectRatio}`}
          style={{
            backgroundColor: `${color.color}`
            // border: color.custom && "1px solid #d9d9d9",
          }}
        >
        </div>
        <Card.Body className={`${style.cardBody} py-1 px-0 d-flex justify-content-around`}>
          <Card.Title className={style.cardTitle}>
            <Row className="align-items-center">
              <Col>
                {color.color}
              </Col>
              <Col>
                <Button
                  variant="light"
                  onClick={() => handleDeleteColor(color)}
                  size="sm"
                  className={`${style.detailsBtn} ${style.deleteBtn}`}
                  disabled={sortable && true}
                >
                  <GoTrash />
                </Button>
              </Col>
            </Row>
          </Card.Title>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ColorCardHexaTool;
