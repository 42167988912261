import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GoTrash } from "react-icons/go";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useColorMatch, useScreenSize } from "../../../utils";
import { useAppContext, useColorMatchContext } from "../../../context";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import style from "./ColorCard.module.css";

const ColorCard = ({
  color,
  modal,
  setModal,
  sortable,
  share,
  setSelectedColor,
  internal,
  savedPalette,
}) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { removeBalloon } = useColorMatchContext();
  const [nameCharacters, setNameCharacters] = useState(100);
  const [hovered, setHovered] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      animateLayoutChanges: () => false,
      id: color.id,
    });
  const { closerColor } = color;
  let item = closerColor ? closerColor : color;

  const userPro = true;
  /* user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing"; */

  const { mutate: fetchBalloon } = useColorMatch();
  const onSuccess = useCallback(
    (data) => {
      setModal({ ...modal, show: true, color: data });
    },
    [modal, setModal]
  );

  const onError = useCallback((error) => {
    console.error("Error:", error);
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const screenSize = useScreenSize();

  const handleClickMoreInfo = (color) => {
    if (savedPalette && !userPro) {
      fetchBalloon(
        { elementSelected: color.id, userPro },
        { onSuccess, onError }
      );
    }
    setModal({ ...modal, show: true, color });
  };

  const handleDeleteColor = (balloon) => {
    removeBalloon(balloon.id);
    setSelectedColor("");
  };

  const cardStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  let sortableAttr;

  if (sortable) {
    sortableAttr = { ...attributes };
  }

  useEffect(() => {
    if (screenSize > 1400) {
      setNameCharacters(21);
    } else if (screenSize > 992 && screenSize < 1200) {
      setNameCharacters(11);
    } else if (screenSize > 1200 && screenSize < 1400) {
      setNameCharacters(14);
    } else if (screenSize < 992) {
      setNameCharacters(27);
    }
  }, [screenSize]);

  return (
    <div
      {...sortableAttr}
      {...listeners}
      ref={setNodeRef}
      style={cardStyle}
      className={screenSize < 992 ? "px-2" : undefined}
    >
      <Card
        style={{
          borderRadius: 0,
          border: "none",
        }}
        className={sortable ? style.animatedCard : undefined}
      >
        {/* <div
          style={{
            width: "100%",
            height: "1.5rem",
            backgroundColor: color.colorPicked
              ? color.colorPicked
              : color.hexColor.length === 1
              ? "transparent"
              : `#${color.hexColor.replace("#", "")}`,
          }}
        /> */}
        <div
          className={`${style.imgContainer} ${style.fallbackAspectRatio}`}
          style={{
            // border: color.custom && "1px solid #d9d9d9",
            paddingTop: item?.color && "125%",
          }}
        >
          <Card.Img
            className={style.img}
            variant="top"
            src={item?.image?.[0]?.imageURL || item?.imageURL}
            style={{
              borderRadius: 0,
              filter: item?.color && hovered && !sortable && "brightness(70%)",
              cursor: item?.color && hovered && !sortable && "pointer",
              transition: "filter 0.3s ease",
            }}
            {...(!share && { onMouseEnter: handleMouseEnter })}
            {...(!share && { onMouseLeave: handleMouseLeave })}
            {...(item?.color &&
              !sortable &&
              !share && { onClick: () => handleClickMoreInfo(color) })}
          />
        </div>
        <Card.Body className={`${style.cardBody} py-1 px-0 `}>
          <Card.Title className={style.cardTitle}>
            {/* {share
              ? color.displayName
              : !color.displayName
              ? color.name
              : color.displayName.length > nameCharacters
              ? color.displayName.substring(0, nameCharacters) + "..."
              : color.displayName} */}
            {share && !internal && color?.displayName}
            {share && internal && color?.name}

            {/* {!share && color.name.length > nameCharacters ?
              color.name.substring(0, nameCharacters) + "..."
              : !share ? color.name : null
              } */}

            {!share && (item?.displayName || color?.displayName)
              ? (item?.displayName || color?.displayName).length >
                nameCharacters
                ? (item?.displayName || color?.displayName).substring(
                    0,
                    nameCharacters
                  ) + "..."
                : item?.displayName || color?.displayName
              : item?.name || color?.name}
          </Card.Title>
          {/* {!share && (
            <small className={style.cardBrand}>
              {item.displayName ? item.brand : <br />}
            </small>
          )} */}
          {internal && !color?.combined ? (
            <small className={style.cardBrand}>
              {color?.displayName ? color?.brand : <br />}
            </small>
          ) : null}

          {internal && color?.combined && (
            <>
              <p className={`${style.cardBrand} mt-2 mb-1`}>OUTSIDE/INSIDE</p>
              <p className={`${style.cardBrand}`}>
                {color?.brand.split("/")[0]} - {color?.outsideColor} /{" "}
                {color?.brand.split("/")[1]} - {color?.insideColor}
              </p>
            </>
          )}
          {!share && (
            <Row className="m-0 mt-1 p-0 align-items-center justify-content-between">
              <Col className="m-0 p-0" xs={8}>
                <Button
                  onClick={() => handleClickMoreInfo(color)}
                  variant="light"
                  className={`${style.detailsBtn} ${style.viewDetailsBtn} w-100 justify-content-center`}
                  disabled={(sortable || !color) && true}
                >
                  {t("View more")}
                </Button>
              </Col>
              <Col className="m-0 p-0 flex justify-content-end" xs={3}>
                <Button
                  variant="light"
                  onClick={() => handleDeleteColor(color)}
                  size="sm"
                  className={`${style.detailsBtn} ${style.deleteBtn} w-100 justify-content-center`}
                  disabled={sortable && true}
                >
                  <GoTrash />
                </Button>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ColorCard;
