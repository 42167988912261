import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { Toaster } from "sonner";
import {
  Alert,
  sizes,
  useDeleteBalloonV2,
  useDeleteUploadedImagesStorage,
} from "../../../../utils";
import style from "../Balloons.module.css";

const BalloonDetails = ({ item, setShowDetails }) => {
  const [balloon, setBalloon] = useState({});
  const [confirmDelete, setConfirmDelete] = useState();
  const [deleting, setDeleting] = useState();
  const deleteBalloon = useDeleteBalloonV2();
  const { mutate: deleteImagesFromStorage, isLoading } =
    useDeleteUploadedImagesStorage();

  useEffect(() => {
    if (item) {
      setBalloon(item);
    }
  }, [item]);

  const handleSetConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const imagePathsToDelete = ({ images }) => {
    if (Array.isArray(images)) {
      return images.map((image) => {
        return deleteImagesFromStorage({
          imagePath: image.imagePath,
          imageURL: image.imageURL,
        });
      });
    } else if (typeof images === "string") {
      return deleteImagesFromStorage({
        imageURL: images,
      });
    }
  };

  const handleDeleteBalloon = async () => {
    setDeleting(true);
    const docId = balloon.color;

    try {
      imagePathsToDelete({ images: balloon.image || balloon.imageURL });
      deleteBalloon.mutate(docId);
      Alert.success("Balloon deleted successfully");
      setShowDetails();
      setDeleting(false);
    } catch (error) {
      console.log(error);
      setDeleting(false);
      Alert.error("There was an error submitting");
    }
  };

  return (
    <Row className={`m-0 p-0 gap-4 ${style.ballonsDetailsContainer}`}>
      <Col md={4}>
        <Image
          src={balloon.image ? balloon.image[0].imageURL : balloon.imageURL}
          width={260}
        />
      </Col>
      <Col className={style.balloonDetails}>
        <Row>
          <Col>
            <div>
              <span className={style.detailTitles}>Manufacturer's Name: </span>
              <span className={style.detailTexts}>{balloon.name}</span>
            </div>
            <div>
              <span className={style.detailTitles}>Display Name: </span>
              <span className={style.detailTexts}>{balloon.displayName}</span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Hex Color: </span>
              <span className={style.detailTexts}>{balloon.color}</span>
              <span
                style={{ backgroundColor: balloon.color }}
                className={style.hexColorCircle}
              ></span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Layered: </span>
              <span className={style.detailTexts}>
                {balloon.combined ? "Yes" : "No"}{" "}
              </span>
            </div>
            {balloon.combined ? (
              <div className="mt-2">
                <span className={style.detailTitles}>Layers: </span>
                <ul className={style.detailTexts}>
                  <li>
                    <b>Inside Color:</b> {balloon.insideColor}
                  </li>
                  <li>
                    <b>Outside Color:</b> {balloon.outsideColor}
                  </li>
                </ul>
              </div>
            ) : null}
            <div className="mt-2">
              <span className={style.detailTitles}>Brand: </span>
              {balloon.combined ? (
                <ul className={style.detailTexts}>
                  <li>
                    <b>Inside Brand:</b> {balloon.insideBrand}
                  </li>
                  <li>
                    <b>Outside Brand:</b> {balloon.outsideBrand}
                  </li>
                </ul>
              ) : (
                <span className={style.detailTexts}>{balloon.brand}</span>
              )}
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Category: </span>
              <span className={style.detailTexts}>{balloon.category}</span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Color category: </span>
              {balloon.colorCategories?.map((color, index) => (
                <span key={index} className={style.detailTexts}>
                  {color}
                </span>
              ))}
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Generic Finish: </span>
              <span className={style.detailTexts}>{balloon.genericFinish}</span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Generic Color: </span>
              <span className={style.detailTexts}>{balloon.genericColor}</span>
            </div>
          </Col>
          <Col>
            <div>
              <span className={style.detailTitles}>Available Sizes:</span>
            </div>
            <ul className={style.detailTexts}>
              {sizes.map((size, index) => {
                const formattedSize = size.toString().replace(/\./g, "_");
                const sizeKey = `size${formattedSize}`;
                const baseSize = formattedSize.split("_")[0];

                return (
                  balloon[sizeKey] && (
                    <li key={index}>
                      {size.toString().includes(".")
                        ? `${baseSize}" LINK`
                        : size > 100 || size.toString().includes("h")
                        ? `${baseSize}`
                        : `${size}"`}
                    </li>
                  )
                );
              })}
            </ul>
          </Col>
        </Row>
        <Row className={style.deleteBalloonContainer}>
          <Col md={4}>
            <Button size="sm" variant="danger" onClick={handleSetConfirmDelete}>
              <FaTrash /> Delete balloon
            </Button>
          </Col>
          {confirmDelete && (
            <Row
              className={`justify-content-center ${style.confirmDeleteContainer}`}
            >
              <p className={style.deleteAlert}>
                Deleting this balloon is permanent. Click DELETE to confirm or
                close this window to go back.
              </p>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={handleDeleteBalloon}
                >
                  DELETE
                  {(deleting || isLoading) && (
                    <>
                      <span> </span>
                      <Spinner size="sm" animation="grow" />
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Col>
      <Toaster richColors position="bottom-center" />
    </Row>
  );
};

export default BalloonDetails;
