import { useState, useMemo, useCallback } from "react";
import { colorCategories } from "../index";

const useFilterByColorCategory = (data) => {
  const [colorCategoriesSelected, setColorCategoriesSelected] = useState(() =>
    Object.fromEntries(
      colorCategories.map((colorCategory) => [colorCategory.name, false])
    )
  );

  const handleColorCategoryChange = useCallback(
    (colorCategory) => {
      setColorCategoriesSelected((prevState) => ({
        ...prevState,
        [colorCategory]: !prevState[colorCategory],
      }));
    },
    [setColorCategoriesSelected]
  );

  const clearAllColorCategories = useCallback(() => {
    setColorCategoriesSelected(
      Object.fromEntries(
        colorCategories.map((colorCat) => [colorCat.name, false])
      )
    );
  }, []);

  const filteredColorCategories = useMemo(() => {
    const colorCategoriesToInclude = new Set(
      Object.keys(colorCategoriesSelected).filter(
        (colorCategory) => colorCategoriesSelected[colorCategory]
      )
    );

    if (colorCategoriesToInclude.size === 0) {
      return data;
    }

    return data?.filter((balloon) => {
      return balloon.colorCategories.some((colorCategory) =>
        colorCategoriesToInclude.has(colorCategory)
      );
    });
  }, [colorCategoriesSelected, data]);

  return {
    colorCategoriesSelected,
    handleColorCategoryChange,
    clearAllColorCategories,
    filteredColorCategories,
  };
};

export default useFilterByColorCategory;
