import React from "react";
import { Modal } from "react-bootstrap";
import style from "./ModalWindow.module.css";
import { useScreenSize } from "../../utils";

const ModalWindow = ({
  show,
  onHide,
  centered,
  size,
  fullscreen,
  closeButton,
  title,
  header,
  body,
  footer,
  helpModal,
  addModal,
  viewDetailsModal,
  upgradeToProModal
}) => {
  const screenSize = useScreenSize();
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
      fullscreen={fullscreen}
      backdrop="static"
      style={helpModal && { borderColor: "#F16553" }}
      className={(addModal || viewDetailsModal) && style.addModal}
      id={viewDetailsModal ? 'view-details-modal' : upgradeToProModal ? 'upgradeToProModal' : ''}
    >
      {title || header ? (
        <Modal.Header className={viewDetailsModal ? style.headerViewDetails : style.header} closeButton={closeButton}>
          {title ? (
            viewDetailsModal ? 
              screenSize < 992 ? null : <Modal.Title className={viewDetailsModal ? style.modalTitleViewDetails : style.modalTitle}>{title}</Modal.Title> 
            : <Modal.Title className={viewDetailsModal ? style.modalTitleViewDetails : style.modalTitle}>{title}</Modal.Title> 
          ) : (
            header
          )}
        </Modal.Header>
      ) : (
        closeButton && <Modal.Header className={viewDetailsModal ? style.headerViewDetails : style.header} closeButton={closeButton} />
      )}
      <Modal.Body
        className={style.body}
      >
        {body}
      </Modal.Body>
      {footer && <Modal.Footer className={style.footer}>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalWindow;
