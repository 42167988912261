import React from "react";
import { ListGroup } from "react-bootstrap";
import { FiDownload, FiShare } from "react-icons/fi";
import { useColorMatchContext } from "../../../../context";
import { BottomSheet } from "../../../index";
import style from "../MobileCommands.module.css";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const MoreBottomSheet = ({
  showBottomSheet,
  setShowBottomSheet,
  setShow,
  userProVersion,
  setShowProVersionModal,
}) => {
  const { colormatch } = useColorMatchContext();
  const { t } = useTranslation();
  return (
    <BottomSheet
      open={showBottomSheet}
      setOpen={() =>
        setShowBottomSheet((prevState) => ({
          ...prevState,
          more: false,
        }))
      }
    >
      <ListGroup>
        <ListGroup.Item
          action
          disabled={colormatch.length < 1}
          className={style.mainOption}
          onClick={
            userProVersion
              ? () => {
                  setShow({ download: true });
                  setShowBottomSheet((prevState) => ({
                    ...prevState,
                    more: false,
                  }));
                }
              : () => {
                  setShowBottomSheet((prevState) => ({
                    ...prevState,
                    more: false,
                  }));
                  setShowProVersionModal(true);
                }
          }
        >
          <span className="d-flex align-items-center">
            <FiDownload size="1.5rem" /> {t("Download")}{" "}
            {!userProVersion && <FaStar color="#FF6563" className="ms-1" />}
          </span>
        </ListGroup.Item>
        <ListGroup.Item
          action
          disabled={colormatch.length < 1}
          className={style.mainOption}
          onClick={
            userProVersion
              ? () => {
                  setShow({ share: true });
                  setShowBottomSheet((prevState) => ({
                    ...prevState,
                    more: false,
                  }));
                }
              : () => {
                  setShowBottomSheet((prevState) => ({
                    ...prevState,
                    more: false,
                  }));
                  setShowProVersionModal(true);
                }
          }
        >
          <span className="d-flex align-items-center">
            <FiShare size="1.5rem" /> {t("Share")}{" "}
            {!userProVersion && <FaStar color="#FF6563" className="ms-1" />}{" "}
          </span>
        </ListGroup.Item>
      </ListGroup>
    </BottomSheet>
  );
};

export default MoreBottomSheet;
