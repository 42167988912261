import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "path-browserify";
import { AppProvider, ColorMatchProvider } from "./context";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppRoutes } from "./Routes";
import "./utils/language/i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Crea una instancia de QueryClient
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="1052435946542-eqd13hkbt3hju9b3qvs5pgd6l1cd37ga.apps.googleusercontent.com">
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <ColorMatchProvider>
          <AppRoutes />
        </ColorMatchProvider>
      </AppProvider>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);

serviceWorkerRegistration.register();
