import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const FormControl = ({
  label,
  name,
  onChange,
  value,
  feedback,
  icon,
  decoration,
  customClass,
  ...rest
}) => {
  return (
    <Form.Group>
      {label && <Form.Label className={customClass}>{label}</Form.Label>}
      <InputGroup>
        {icon && (
          <InputGroup.Text
            style={{ backgroundColor: "white", border: "solid 1px #d9d9d9" }}
          >
            {icon}
          </InputGroup.Text>
        )}
        <Form.Control onChange={onChange} name={name} value={value} {...rest} />
        {feedback && (
          <Form.Control.Feedback type={feedback.type}>
            {feedback.message}
          </Form.Control.Feedback>
        )}
        {decoration && (
          <InputGroup.Text className="p-0" style={{ backgroundColor: "white" }}>
            {decoration}
          </InputGroup.Text>
        )}
      </InputGroup>
    </Form.Group>
  );
};

export default FormControl;
