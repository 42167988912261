import React, { useState } from "react";
import colorDifference from "color-difference";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import style from "../Dashboard.module.css";
import { Alert } from "../../../utils";
import { Toaster } from "sonner";

const HexDistanceCalculator = () => {
  const [distance, setDistance] = useState(null);
  const [hex1, setHex1] = useState("");
  const [hex2, setHex2] = useState("");

  const handleChangeHex1 = (e)=>{
    setDistance("")
    setHex1(e.target.value);
  }

  const handleChangeHex2 = (e)=>{
    setDistance("")
    setHex2(e.target.value);
  }

  const handleCalculateDistance = ()=>{
    let calculatedDistance;
    try {
      calculatedDistance = colorDifference.compare(hex1, hex2);
      setDistance(calculatedDistance);
    } catch (error) {
      Alert.error("Error. Try again")
      console.log(error);
      
    }
  }

  return (
    <Container>
      <Row className="mt-4 text-center ">
        <h1 className={style.title}>Hex Distance Calculator</h1>
        <Col></Col>
        <Col></Col>
        <Col>
          <Form.Label htmlFor="hex1">Hex1</Form.Label>
          <Form.Control type="text" id="hex1" onChange={handleChangeHex1} value={hex1}></Form.Control>
        </Col>
        <Col>
          <Form.Label htmlFor="hex2">Hex2</Form.Label>
          <Form.Control type="text" id="hex2" onChange={handleChangeHex2} value={hex2}></Form.Control>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <Row className="text-center mt-4">
        <Col>
          <Button onClick={handleCalculateDistance} className={style.calculateBtn}>CALCULATE</Button>
        </Col>
      </Row>
      <Row>
        {distance && 
          <h3 className="text-center mt-4">Distance: {distance}</h3>
        }
      </Row>
      <Toaster richColors position="bottom-center" />
    </Container>
  )
}

export default HexDistanceCalculator;