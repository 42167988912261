import React, { useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
  doc,
} from "firebase/firestore";
import { app } from "../../../../firebase";
import { Toaster } from "sonner";
import {
  Alert,
  useAddAccessories,
  useUpdateAccessory,
  accessoriesCategories,
  colorCategories,
  imagesMapToUpload,
  useDeleteUploadedImagesStorage,
} from "../../../../utils";
import {
  AutoCompleteFilters,
  Inputs,
  ReusableForm,
  useForm,
} from "../../../../components";
import style from "./AccessoriesForm.module.css";

const initialValues = {
  id: "",
  name: "",
  image: null,
  category: "none",
  hexColor: "",
  colorCategories: [],
};

const db = getFirestore(app);

const AccessoriesForm = ({ editItem, setShow, setMissingImage }) => {
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [selected, setSelected] = useState([]);
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [error, setError] = useState("");
  const [editImage, setEditImage] = useState(true);
  const [loading, setLoading] = useState(false);
  const addAccessory = useAddAccessories();
  const { mutate: deleteImagesFromStorage } = useDeleteUploadedImagesStorage();
  const updateAccessory = useUpdateAccessory();

  useEffect(() => {
    if (editItem) {
      let objectArray;
      if (editItem.colorCategories) {
        objectArray = editItem.colorCategories.map((colorCat) => ({
          name: colorCat,
        }));
        setSelected(objectArray);
      }
      editItem.hexColor = editItem.hexColor.replace("#", "");
      setValues(editItem);
      setEditImage(false);
    }
  }, [editItem, setValues]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return;
    }
    setError("");
    setLoading(true);
    const accessoryToSend = { ...values };

    accessoryToSend.hexColor = accessoryToSend.hexColor.toLowerCase();
    accessoryToSend.colorCategories = selected.map((i) => i.name);
    if (!accessoryToSend.hexColor.includes("#")) {
      accessoryToSend.hexColor = "#" + accessoryToSend.hexColor;
    }

    const newDocRef = doc(collection(db, "accessories"));
    const newId = newDocRef.id;
    accessoryToSend.id = newId;
    try {
      const q = query(
        collection(db, "accessories"),
        where("name", "==", accessoryToSend.name)
      );
      const querySnapshot = await getDocs(q);
      let docExist = false;

      if (!querySnapshot.empty) {
        docExist = true;
      }

      if (docExist && !editItem) {
        Alert.error("There is already a accessory with this name");
        setLoading(false);
        return;
      }

      if (editImage) {
        if (values.image === null) {
          setError("You must upload an image");
          setLoading(false);
          return;
        } else {
          try {
            const imageWithURL = await imagesMapToUpload({
              images: values.image,
              setProgress,
              collection: "accessories",
              id: newId,
            });
            accessoryToSend.image = imageWithURL;
          } catch (error) {
            Alert.error("Error processing image, try again");
            setLoading(false);
            return;
          }
        }
      }

      if (editItem) {
        accessoryToSend.id = editItem.id;

        if (docExist && editItem.name === values.name) {
          const originalData = query(
            collection(db, "accessories"),
            where("name", "==", editItem.name)
          );
          const queryOriginalData = await getDocs(originalData);
          const documentIds = [];
          queryOriginalData.forEach((doc) => {
            documentIds.push(doc.id);
          });
          updateAccessory.mutate({
            accessoryId: documentIds[0],
            accessory: accessoryToSend,
          });
          setMissingImage(false);
        } else if (docExist) {
          Alert.error("There is already a accessory with this name");
          setLoading(false);
          return;
        } else {
          const originalData = query(
            collection(db, "accessories"),
            where("name", "==", editItem.name)
          );
          const queryOriginalData = await getDocs(originalData);
          const documentIds = [];
          queryOriginalData.forEach((doc) => {
            documentIds.push(doc.id);
          });
          updateAccessory.mutate({
            accessoryId: documentIds[0],
            accessory: accessoryToSend,
          });
          setMissingImage(false);
        }
      } else {
        addAccessory.mutate({
          accessory: accessoryToSend,
          id: newId,
        });
      }

      setLoading(false);
      Alert.success("Submitted successfully");
      setTimeout(() => {
        setShow();
      }, 1000);
    } catch (error) {
      Alert.error("There was an error submitting");
      console.log(error);
      setLoading(false);
    }
  };

  const imagePathsToDelete = ({ images }) => {
    if (Array.isArray(images)) {
      return images.map((image) => {
        return deleteImagesFromStorage({
          imagePath: image.imagePath,
          imageURL: image.imageURL,
        });
      });
    } else if (typeof images === "string") {
      return deleteImagesFromStorage({
        imageURL: images,
      });
    }
  };

  const handleSetEditImage = () => {
    imagePathsToDelete({ images: editItem.image || editItem.imageURL });
    setValues({ ...values, image: null });
    setEditImage(true);
    setMissingImage(true);
  };

  const validateSubmit = () => {
    const hexColorRegex = /^[0-9A-Fa-f]{6}$/;
    if (values.name === "") {
      setError("You must insert a name");
      return false;
    } else if (values.category === "none") {
      setError("You must select a category");
      return false;
    } else if (!hexColorRegex.test(values.hexColor)) {
      setError("You must insert a valid hex color code");
      return false;
      /* } else if (selected.length === 0) {
      setError("You must select at least one color category");
      return false; */
    } else if (values.image === null) {
      setError("You must upload an image");
      return false;
    } else {
      return true;
    }
  };

  const handleImageChange = (e) => {
    setValues({ ...values, image: [e.target.files[0]] });
    setMissingImage(false);
  };

  return (
    <ReusableForm
      className={style.form}
      onSubmit={(e) => handleSubmit(e, values.name)}
    >
      <Row>
        <Col>
          <Inputs.FormControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            type="text"
          />
        </Col>
        <Col>
          <Inputs.Select
            label="Category"
            onChange={handleInputChange}
            value={values.category}
            name="category"
            options={accessoriesCategories}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Inputs.FormControl
            label="Hex Color Code"
            icon="#"
            name="hexColor"
            value={values.hexColor}
            onChange={handleInputChange}
            placeholder="F3F3F3"
            type="text"
            decoration={
              <span
                className={style.hexColorSelected}
                style={{ backgroundColor: `#${values.hexColor}` }}
              ></span>
            }
          />
        </Col>
        <Col>
          <AutoCompleteFilters
            optionalTitle="Color categories"
            colorCategories={colorCategories}
            selected={selected}
            setSelected={setSelected}
            placeholder="Select a color category"
            renderOption={(option) => (
              <small className={style.optionTitle}>{option.name}</small>
            )}
          />
        </Col>
      </Row>
      {editImage ? (
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Accessory Image</Form.Label>
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
        </Col>
      ) : (
        <Col className={style.changeImg}>
          <Button onClick={handleSetEditImage}>Change image</Button>
        </Col>
      )}

      <span className={style.errorTxt}>{error}</span>

      {progress > 0 && (
        <ProgressBar
          min={0}
          now={Math.min(progress, 100)}
          max={100}
          striped
          animated
          label={progress === 100 ? "Complete" : `${Math.min(progress, 100)}%`}
          variant={progress === 100 && "success"}
        />
      )}
      <Button className={style.submitBtn} type="submit">
        Submit to database
        {loading && (
          <>
            <span> </span>
            <Spinner size="sm" animation="grow" />
          </>
        )}
      </Button>
      <Toaster richColors position="bottom-center" />
    </ReusableForm>
  );
};

export default AccessoriesForm;
