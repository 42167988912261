import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  updateDoc,
  getFirestore,
  doc,
  increment,
  addDoc,
  collection,
  getDocs,
  where,
  query,
  deleteDoc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { app, storage } from "../../firebase";

const db = getFirestore(app);

// --------------- Image uploaded counter ---------------

const updateImageUploadedCounter = async ({ email, imageCount }) => {
  await updateDoc(doc(db, "usuarios", email), {
    imagesUploaded: increment(imageCount),
  });
};

export const useUpdateImageUploadedCounter = () => {
  const queryClient = useQueryClient();
  return useMutation(updateImageUploadedCounter, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("usuarios");
    },
  });
};

// --------------- Images Uploaded ---------------

const fetchImages = async () => {
  const querySnapshot = await getDocs(collection(db, "imagesUploaded"));
  return querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });
};

export const useImagesUploaded = () => {
  return useQuery("imagesUploaded", fetchImages);
};

const addImage = async (image) => {
  await addDoc(collection(db, "imagesUploaded"), image);
};

export const useAddImages = () => {
  const queryClient = useQueryClient();
  return useMutation(addImage, {
    onSuccess: () => {
      queryClient.invalidateQueries("imagesUploaded");
    },
  });
};

const deleteUploadedImages = async ({ user, imagePath }) => {
  const q = query(
    collection(db, "imagesUploaded"),
    where("user", "==", user),
    where("path", "==", imagePath)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0];
    await deleteDoc(doc(db, "imagesUploaded", docSnapshot.id));
  } else {
    console.log("Doc not found");
  }
};

/* Funcion para imagenes subidas por los usuarios (dashboard) */
export const useDeleteUploadedImages = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteUploadedImages, {
    onSuccess: () => {
      queryClient.invalidateQueries("imagesUploaded");
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });
};

const deleteUploadedImagesStorage = async ({ imagePath, imageURL }) => {
  let imageRef;

  if (imagePath) {
    imageRef = ref(storage, imagePath);
  } else if (imageURL) {
    const baseUrl =
      "https://firebasestorage.googleapis.com/v0/b/paris312-colormatch-dev.appspot.com/o/";

    if (imageURL.startsWith(baseUrl)) {
      const decodedUrl = decodeURIComponent(imageURL);
      const filePath = decodedUrl.replace(baseUrl, "").split("?")[0];
      imageRef = ref(storage, filePath);
    } else {
      throw new Error("URL no válida para Firebase Storage");
    }
  } else {
    throw new Error("Se debe proporcionar 'image' o 'imageURL'");
  }

  // Eliminar el archivo
  await deleteObject(imageRef);
};

export const useDeleteUploadedImagesStorage = () => {
  return useMutation(deleteUploadedImagesStorage, {
    onSuccess: () => {
      console.log("File deleted");
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });
};
