export const privacyEs = [
  {
    text: `Política de privacidad de Balloon Color Match
    Última actualización: 12 de junio de 2024
    
    En Balloon Color Match (“Balloon Color Match”, “nosotros”, “nos” o “nuestro”) creamos esta política de privacidad (“Política de privacidad”) porque su privacidad es de suma importancia para nosotros. Nuestra política de privacidad explicará cómo Balloon Color Match recopila, utiliza y divulga información personal con respecto a su acceso y uso del sitio, las aplicaciones y los servicios de Balloon Color Match (“Balloon Color Match”).
    
    Al acceder y utilizar Balloon Color Match, acepta los términos de esta Política de privacidad y los Términos de uso que la acompañan. Si no acepta alguno de estos términos, no utilice Balloon Color Match. Los términos en mayúsculas que no se definen en esta Política de privacidad tendrán el significado establecido en nuestros Términos de uso.
    
    Información que recopilamos
    No recopilamos ninguna información personal o confidencial suya a menos que nos la proporcione voluntariamente.
    Si desea utilizar Balloon Color Match, deberá acceder a la aplicación desde el sitio creando una cuenta. Al acceder a Balloon Color Match, se le solicitará que proporcione información para crear una cuenta. Dicha información puede incluir, sin limitación, su nombre y apellido, dirección de correo electrónico, nombre de la empresa, número de teléfono, ubicación y foto de perfil. En esta Política de privacidad, nos referimos a esta información como "Información confidencial".
    Podemos utilizar tecnología de análisis de terceros para rastrear y recopilar datos de tráfico anónimos sobre el uso de Balloon Color Match. Esta información incluye información sobre el tipo de dispositivo que está utilizando, el modelo del dispositivo, el sistema operativo, la versión de Balloon Color Match, sus direcciones IP, el tipo de navegador y el idioma, las páginas y URL de referencia y salida, la fecha y la hora, la cantidad de tiempo que pasa en páginas particulares, qué secciones de Balloon Color Match visita e información similar que nos ayudará a analizar y evaluar cómo se utiliza Balloon Color Match ("Información de uso"). Los proveedores de tecnología de terceros pueden tener acceso a su información solo con el fin de realizar estas tareas y en nombre de Balloon Color Match y bajo obligaciones similares a las de esta Política de privacidad.
    También recopilamos información que recopilamos mediante la tecnología de "cookies". Las cookies son pequeños paquetes de datos que un sitio web almacena en el disco duro de su computadora o dispositivo para que su computadora “recuerde” información sobre su visita. Podemos utilizar tanto cookies de sesión (que expiran una vez que cierra su navegador web) como cookies persistentes (que permanecen en su computadora hasta que las elimine) para ayudarnos a recopilar información de uso y mejorar su experiencia al usar Balloon Color Match. Si no desea que coloquemos una cookie en su disco duro, puede desactivar esa función en su computadora o dispositivo. Consulte la documentación de su navegador de Internet para obtener información sobre cómo hacerlo y cómo eliminar las cookies persistentes. Sin embargo, si decide no aceptar nuestras cookies, es posible que Balloon Color Match no funcione correctamente.
    No recopilamos información personal de esta manera, pero si nos ha proporcionado información personal, podemos asociar esa información con la información que se recopila automáticamente. La recopilación automática de datos puede ser realizada en nuestro nombre por nuestros proveedores de servicios.`,
  },
  {
    title: `Cómo usamos su información`,
    text: `Usaremos la información confidencial y la información de uso recopilada por Balloon Color Match para los siguientes fines:
    · Para responder a sus solicitudes y proporcionarle Balloon Color Match;
    · Para responder a sus consultas y comunicarnos con usted sobre cambios en Balloon Color Match;
    · Para enviarle avisos (por ejemplo, en forma de correos electrónicos, correos postales y similares)
    · Para cualquier otro propósito divulgado en el momento en que se recopila la información o para el cual usted da su consentimiento; y
    · Como se describe específicamente en esta Política de privacidad.
    También podemos usar su información para mejorar Balloon Color Match y podemos usar otra información para permitirnos personalizar su experiencia de usuario.
    Nosotros, como muchas empresas, a veces contratamos a otras empresas para realizar ciertas funciones relacionadas con el negocio. Los ejemplos incluyen información de correo, mantenimiento de bases de datos, servicios de alojamiento y procesamiento de pagos. Cuando contratamos a otra empresa para realizar una función de esta naturaleza, les proporcionamos la información que necesitan para realizar su función específica, que puede incluir información personal. Exigimos a dichos terceros que mantengan la confidencialidad de dicha información y la utilicen solo en la medida necesaria para proporcionarnos los servicios designados.
    Si adquirimos todos o casi todos nuestros activos, esperamos que la información que hayamos recopilado, incluida la Información confidencial, se transfiera junto con nuestros otros activos comerciales.
    Podemos divulgar su Información confidencial e Información de uso a las autoridades gubernamentales y a otros terceros cuando las autoridades gubernamentales nos obliguen a hacerlo, a nuestra discreción o de otro modo según lo exija la ley, incluso, entre otros, en respuesta a órdenes judiciales y citaciones. También podemos divulgar su Información confidencial e Información de uso cuando tengamos motivos para creer que alguien está o puede estar causando daño o interferencia con nuestros derechos o propiedad, otros usuarios de este Balloon Color Match o cualquier otra persona que pueda verse perjudicada por dichas actividades.
    
    Cómo protegemos su información confidencial
    Tomamos medidas comercialmente razonables para proteger la información confidencial contra pérdida, uso indebido y acceso, divulgación, alteración o destrucción no autorizados. Sin embargo, comprenda que ningún sistema de seguridad es impenetrable. No podemos garantizar la seguridad de nuestras bases de datos ni podemos garantizar que la información que usted nos proporcione no sea interceptada mientras se transmite hacia y desde nosotros a través de Internet.
    
    Acceso y modificación de la información de la cuenta
    Puede actualizar la información almacenada en su cuenta de usuario enviándonos un correo electrónico a 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚 .`,
  },
  {
    title: `Materiales promocionales`,
    text: `Si desea dejar de recibir correos electrónicos promocionales, puede hacerlo enviándonos un correo electrónico a 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚. También puede optar por cancelar la suscripción a nuestros correos electrónicos siguiendo las instrucciones que se encuentran en la parte inferior del correo electrónico.`,
  },
  {
    title: `Enlaces externos`,
    text: `La aplicación web Balloon Color Match contendrá enlaces a sitios externos. No tenemos control sobre esos otros sitios y aplicaciones, y esta Política de privacidad no se aplica a ellos. Consulte los términos de uso y las políticas de privacidad de los sitios externos para obtener más información.`,
  },
  {
    title: `Aviso importante para residentes no estadounidenses`,
    text: `La aplicación Balloon Color Match se ejecuta en los Estados Unidos. Si se encuentra fuera de los Estados Unidos, tenga en cuenta que cualquier información que nos proporcione se transferirá a los Estados Unidos. Al proporcionarnos cualquier información a través de la aplicación Balloon Color Match, usted acepta esta transferencia y nuestro uso de dicha información de acuerdo con esta Política de privacidad.`,
  },
  {
    title: `Niños`,
    text: `No recopilamos deliberadamente información personal de usuarios menores de 18 años a través de la aplicación Balloon Color Match. Si es menor de 18 años, no nos proporcione ninguna información personal. Alentamos a los padres y tutores legales a que supervisen el uso de Internet de sus hijos y a que ayuden a hacer cumplir nuestra Política de privacidad instruyendo a sus hijos a que nunca proporcionen información personal a través de la aplicación sin su permiso. Si tiene motivos para creer que un usuario menor de 18 años nos ha proporcionado información personal, comuníquese con nosotros y haremos todo lo posible por eliminar esa información de nuestras bases de datos.`,
  },
  {
    title: `Residentes de California`,
    text: `Según la Sección 1798.83 del Código Civil de California, los residentes de California que tengan una relación comercial establecida con nosotros pueden optar por no compartir su información personal con terceros con fines de marketing directo. Si es residente de California y (1) desea optar por no compartir su información personal; o (2) desea solicitar cierta información sobre nuestra divulgación de su información personal a terceros con fines de marketing directo, comuníquese con nosotros como se establece a continuación.`,
  },
  {
    title: `Cambios a esta Política de privacidad`,
    text: `Esta Política de privacidad entra en vigencia a partir de la fecha indicada en la parte superior de esta Política de privacidad. Podemos cambiar esta Política de privacidad de vez en cuando y publicaremos cualquier cambio en el sitio web www.ballooncolormatch.com tan pronto como entren en vigencia. Al acceder y/o utilizar la aplicación Balloon Color Match después de que realicemos dichos cambios a esta Política de privacidad, se considerará que ha aceptado dichos cambios. Vuelva a consultar esta Política de privacidad periódicamente.`,
  },
  {
    title: `Cómo contactarnos`,
    text: `Si tiene preguntas sobre esta Política de privacidad, envíenos un correo electrónico a 𝑠𝑢𝑝𝑝𝑜𝑟𝑡@𝑏𝑎𝑙𝑙𝑜𝑜𝑛𝑐𝑜𝑙𝑜𝑟𝑚𝑎𝑡𝑐ℎ.𝑐𝑜𝑚 , “POLÍTICA DE PRIVACIDAD” en la línea de asunto.
    También puede escribir a Balloon Color Match a:
    455 Main St, 12F, New York, NY 10044.`,
  },
  {
    title: `Fuerza mayor`,
    text: `No seremos considerados responsables de ninguna demora o incapacidad para realizar cualquier aspecto del uso de la aplicación Balloon Color Match debido a circunstancias fuera de nuestro control. Tales casos incluyen, pero no se limitan a, actos de la naturaleza, modificaciones de leyes o regulaciones, embargos, guerra, actividades terroristas, disturbios civiles, incendios, eventos sísmicos, incidentes nucleares, inundaciones, huelgas laborales, cortes de energía, erupciones volcánicas, condiciones climáticas excepcionalmente inclementes e intrusiones de piratas informáticos o proveedores de servicios de Internet de terceros.`,
  },
  {
    title: `Misceláneos`,
    text: `En el caso de que alguna parte de estos Términos de uso se considere inválida o inaplicable, dicha parte se interpretará de acuerdo con la ley aplicable lo más fielmente posible para reflejar las intenciones originales de las partes, y el resto de estos Términos de uso permanecerá en plena vigencia y efecto. Esta sección, la sección titulada Propiedad intelectual, indemnización y la sección titulada Descargo de responsabilidad y limitación de responsabilidad sobrevivirán a la terminación de estos Términos de uso. No puede ceder estos Términos de uso. Ninguna renuncia será efectiva a menos que se haga por escrito. Ni la conducta entre las partes ni la práctica comercial modificarán ninguna disposición de estos Términos de uso. Estos Términos de uso y cualquier otro acuerdo entre las partes celebrado a través de la aplicación Balloon Color Match se regirán e interpretarán de conformidad con las leyes del estado de Illinois. A excepción de los procedimientos iniciados por Balloon Color Match para proteger su propiedad intelectual o información confidencial que puedan presentarse en cualquier tribunal de jurisdicción competente, las partes acuerdan mutuamente que todas y cada una de las disputas que surjan en virtud del presente documento se resolverán exclusivamente en tribunales estatales o federales ubicados en el condado de Cook, Illinois. Estos Términos de uso contienen el acuerdo completo de las partes en relación con la aplicación Balloon Color Match y reemplazan todos los acuerdos existentes y todas las demás comunicaciones orales, escritas o de otro tipo entre las partes en relación con su objeto.`,
  },
  {
    title: `Cómo comunicarse con Balloon Color Match`,
    text: `Si tiene alguna pregunta o comentario sobre estos Términos de uso o la aplicación web Balloon Color Match, comuníquese con Balloon Color Match por correo electrónico a support@ballooncolormatch.com.
    También puede escribir a Balloon Color Match a:
    455 Main St, 12F, New York, NY 10044.`,
  },
];
