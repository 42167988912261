import React, { memo, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { brands } from "../../utils";
import { useTranslation } from "react-i18next";
import style from "./Filters.module.css";

const FilterByBrand = ({
  brandsSelected,
  handleChange,
  clearAll,
  variant,
  customClass,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleApplyFilters = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const { t } = useTranslation();
  return (
    <DropdownBtn
      title={t("Filter by Brand")}
      variant={variant}
      filterDropdownBtn={true}
      showDropdown={showDropdown}
      handleApplyFilters={handleApplyFilters}
      toggleDropdown={toggleDropdown}
      customClass={customClass}
    >
      <Dropdown.Item style={{ textDecoration: "underline", paddingBottom: "8px" }} onClick={clearAll}>
        Clear All
      </Dropdown.Item>
      {brands.map((brand) => (
        <Dropdown.Item
          key={brand}
          onClick={(e) => {
            e.stopPropagation();
            handleChange(brand);
          }}
        >
          <Form.Check
            type="checkbox"
            checked={brandsSelected[brand]}
            name={brand}
            label={brand === "Ellies" ? "Ellie's" : brand}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(brand);
            }}
            onChange={() => {}}
          />
        </Dropdown.Item>
      ))}
      <div className="d-flex justify-content-end px-2">
        <Button
          className={`px-4 ${style.applyFiltersBtn}`}
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </DropdownBtn>
  );
};

export default memo(FilterByBrand);
