import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { genericFinish } from "../../utils";
import style from "./Filters.module.css";

const FilterByCategory = ({
  categoriesSelected,
  handleChange,
  clearAll,
  variant,
  customClass,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const handleApplyFilters = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <DropdownBtn
      title={t("Filter by Finish")}
      variant={variant}
      filterDropdownBtn={true}
      showDropdown={showDropdown}
      handleApplyFilters={handleApplyFilters}
      toggleDropdown={toggleDropdown}
      customClass={customClass}
    >
      <Dropdown.Item style={{ textDecoration: "underline", paddingBottom: "8px" }} onClick={clearAll}>
        Clear All
      </Dropdown.Item>
      {genericFinish.map((category, index) => (
        <Dropdown.Item
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            handleChange(category);
          }}
        >
          <Form.Check
            type="checkbox"
            checked={categoriesSelected[category]}
            name={category}
            label={category}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(category);
            }}
            onChange={() => {}}
          />
        </Dropdown.Item>
      ))}
      <div className="d-flex justify-content-end px-2">
        <Button
          className={`px-4 ${style.applyFiltersBtn}`}
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </DropdownBtn>
  );
};

export default FilterByCategory;
