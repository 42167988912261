import React from 'react';
import { Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import style from './ExportToExcel.module.css';
import { AiOutlineFileExcel } from "react-icons/ai";

const ExportToExcel = ({data, filename}) => {
  const handleExport = () => {
    if (!data || data.length === 0) {
      alert("No hay datos para exportar");
      return;
    }
     let orderedData;
    
     if(filename === "balloons.xlsx"){
      const columnMapping = {
        name: "Manufacturer's Name",
        displayName: "Display Name",
        brand: "Brand",
        colorCategories: "Color Categories",
        color: "Color",
        category: "Category",
        genericColor: "Generic Color",
        genericFinish: "Generic Finish",
        combined: "Layered",
        insideColor: "Inside Color",
        outsideColor: "Outside Color",
        insideBrand: "Inside Brand",
        outsideBrand: "Outside Brand"
      }

      const columnOrder = Object.keys(columnMapping);

      orderedData = data.map(obj => {
        const imageUrl = obj.imageURL || obj.image[0].imageURL || "";

        // Obtener las columnas extra que no están en `columnMapping`
        const extraColumns = Object.keys(obj).filter(key => !columnOrder.includes(key) && key !== "imageURL" && key !== "layers" && key !== "id" && key !== "image");

        let formattedData = {};

        // Asignar las columnas definidas en `columnMapping` en el orden correcto
        columnOrder.forEach(key => {
          formattedData[columnMapping[key]] = Array.isArray(obj[key]) ? obj[key].join(", ") : obj[key] || ""; // Convertir arrays en strings
        });
      
        // Agregar la columna `ImageURL`
        formattedData["ImageURL"] = imageUrl;
      
        // Agregar las demás columnas extra
        extraColumns.forEach(key => {
          formattedData[key] = obj[key];
        });

        return formattedData;
      });

    }else if(filename === "users.xlsx"){
      orderedData = data.map(({ name, lastName, email, company, signupTimestamp }) => ({
       Name: name,
       LastName: lastName,
       Email: email,
       Company: company,
       SignupDate: signupTimestamp ? new Date(signupTimestamp.seconds * 1000) : ""
      }));
    }

    // Crear una hoja de cálculo
    const worksheet = XLSX.utils.json_to_sheet(orderedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

    // Guardar el archivo
    XLSX.writeFile(workbook, filename);
  };

  return (
    <Button onClick={handleExport} className={style.exportBtn} >
      <AiOutlineFileExcel />Export to Excel
    </Button>
  );
}

export default ExportToExcel;