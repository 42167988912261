import React, { memo } from "react";
import { ViewDetailsRow } from "../CardsContainer";
// import style from "./Filters.module.css";

/* const SizeOption = ({ size }) => {
  const formattedSize = size.toString().replace(/\./g, "_");
  const baseSize = formattedSize.split("_")[0];
  const sizeLabel = size.toString().includes(".")
    ? `${baseSize}" LINK`
    : size > 100
    ? `${baseSize}`
    : `${size}"`;

  return (
    <Col xs={"auto"}>
      <small className={style.rowData}>{sizeLabel}</small>
    </Col>
  );
}; */

const BalloonOption = memo(({ option }) => {
  return <ViewDetailsRow item={option} autocomplete={true} />;
});

export default BalloonOption;
