import React, { useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { FaEdit } from "react-icons/fa";
import {
  Container,
  Spinner,
  Row,
  Col,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Toaster } from "sonner";
import { Datagrid, ExportToExcel } from "../../../components";
import { Alert, useUsers, useUpdateUsers } from "../../../utils";
import style from "../Dashboard.module.css";

const roles = [
  { name: "Admin", role: "1" },
  { name: "Visitor", role: "2" },
];

const Users = () => {
  const { data: users, isLoading, isFetching } = useUsers();
  const setUser = useUpdateUsers();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [usersToEdit, setUsersToEdit] = useState({});
  const [edit, setEdit] = useState(false);
  const [enabledSave, setEnabledSave] = useState(false);

  const handleSetEdit = () => {
    setShowUpdateBtn(true);
    setEdit(!edit);
  };

  const handleChangeUserRole = (e, row) => {
    setEnabledSave(true);
    const userToEdit = { ...row };
    userToEdit.role = e.target.value;
    setUsersToEdit((prevState) => ({
      ...prevState,
      [row.email]: userToEdit,
    }));
  };

  const handleSaveChanges = async () => {
    setUser.mutate(usersToEdit);
    if (!setUser.isLoading) {
      setEdit(false);
      setShowUpdateBtn(false);
      setEnabledSave(false);
    }
    Alert.success("Users updated successfully");
  };

  const handleDiscardChanges = () => {
    setUsersToEdit({});
    setEdit(false);
    setShowUpdateBtn(false);
    setEnabledSave(false);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),

    columnHelper.accessor("lastName", {
      header: "Last Name",
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),

    columnHelper.accessor("company", {
      header: "Company",
      cell: ({ row }) => row.original.company || "",
      enableSorting: true,
      size: 200, // Similar a grow: 2
    }),

    columnHelper.accessor("email", {
      header: "Email",
      cell: ({ row, getValue }) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${row.original.email}`}>
              {row.original.email}
            </Tooltip>
          }
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
              width: "100%",
            }}
          >
            {getValue().length > 30
              ? getValue().substring(0, 30) + "..."
              : getValue()}
          </span>
        </OverlayTrigger>
      ),
      enableSorting: true,
      size: 300, // Similar a grow: 3
    }),

    columnHelper.display({
      id: "signupTimestamp",
      header: "Sign up timestamp",
      accessorFn: (row) =>
        row.signupTimestamp
          ? new Date(row.signupTimestamp.seconds * 1000)
          : new Date("2024-08-13"), // 🟢 Usa un objeto Date para ordenar
      cell: ({ getValue }) => {
        const date = getValue();
        if (!date) return "";
        return date.toLocaleString("en-US", {
          hour12: false,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
      enableSorting: true,
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = rowA.getValue(columnId);
        const dateB = rowB.getValue(columnId);

        if (!dateA) return 1; // Si `dateA` es `null`, lo manda al final
        if (!dateB) return -1; // Si `dateB` es `null`, lo manda al final

        return dateA - dateB; // Comparación numérica correcta
      },
      size: 200,
    }),

    columnHelper.accessor("imagesUploaded", {
      header: "Images Uploaded",
      cell: ({ row }) =>
        row.original.imagesUploaded ? (
          <span>{row.original.imagesUploaded}</span>
        ) : (
          ""
        ),
      enableSorting: true,
      enableColumnCentering: true,
    }),

    columnHelper.display({
      id: "role",
      header: "Role",
      cell: ({ row }) =>
        edit ? (
          <Form.Select
            key={row.original.id}
            onChange={(e) => handleChangeUserRole(e, row.original)}
            defaultValue={row.original.role}
          >
            {roles.map((role, index) => (
              <option key={index} value={role.role}>
                {role.name}
              </option>
            ))}
          </Form.Select>
        ) : !isFetching ? (
          row.original.role === "1" ? (
            "Admin"
          ) : (
            "Visitor"
          )
        ) : (
          <Spinner as="span" size="sm" animation="grow" />
        ),
      enableSorting: true,
      size: 80,
    }),

    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: () => (
        <Button onClick={handleSetEdit} size="sm">
          <FaEdit size="0.8rem" />
        </Button>
      ),
      size: 80,
    }),
  ];

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>User List</h1>
        </Col>
        <Col className="d-flex justify-content-end">
          {!isLoading && <ExportToExcel data={users} filename="users.xlsx" />}
        </Col>
      </Row>
      {showUpdateBtn && (
        <Row className="mb-4">
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="danger"
              size="sm"
              onClick={handleDiscardChanges}
              className={style.cancelEditBtn}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              size="sm"
              onClick={handleSaveChanges}
              disabled={!enabledSave}
            >
              Save changes{""}
              {isLoading && (
                <Spinner as="span" size="sm" animation="grow" role="status" />
              )}
            </Button>
          </Col>
        </Row>
      )}
      <Row className={`gap-5 ${style.body} align-items-center`}>
        <Col>
          {!isLoading ? (
            <Datagrid columns={columns} data={users} usersTable={true} filter />
          ) : (
            <Col style={{ textAlign: "center" }}>
              <Spinner />
            </Col>
          )}
        </Col>
        <Toaster richColors position="bottom-center" />
      </Row>
    </Container>
  );
};

export default Users;
