import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { BalloonsDetails, BalloonsForm } from "./index";
import { Alert, useBalloonsV2, useScreenSize } from "../../../utils";
import { Datagrid, ModalWindow, ExportToExcel } from "../../../components";
import style from "../Dashboard.module.css";

const Balloons = () => {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(false);
  const [missingImage, setMissingImage] = useState(false);
  // const [imagesLoading, setImagesLoading] = useState({}); Genera un re-render infinito en el componente Datagrid
  const screenSize = useScreenSize();
  const { data: balloonsList, isLoading } = useBalloonsV2();

  // const handleImageLoad = (id) => {
  //   setImagesLoading((prev) => ({ ...prev, [id]: true }));
  // };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "image",
      header: "Image",
      cell: ({ row }) => (
        <>
          {/* {!imagesLoading[row.original.id] && (
            <Spinner animation="grow" size="sm" />
          )} */}
          <Image
            style={{
              width: "2rem",
              // display: imagesLoading[row.original.id] ? "block" : "none",
            }}
            src={
              row.original.image
                ? row.original.image[0].imageURL
                : row.original.imageURL
            }
            // onLoad={() => handleImageLoad(row.original.id)}
            rounded
          />
        </>
      ),
    }),

    columnHelper.accessor("name", {
      header: "Manufacturer's Name",
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),

    columnHelper.accessor("displayName", {
      header: "Display Name",
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),

    columnHelper.accessor(
      (row) =>
        row.combined ? `${row.outsideBrand}/${row.insideBrand}` : row.brand,
      {
        id: "brand",
        header: "Brand",
        cell: ({ getValue }) => getValue(),
        enableSorting: true,
      }
    ),

    columnHelper.accessor(
      (row) =>
        row.colorCategories && row.colorCategories.length > 0
          ? row.colorCategories.join(", ")
          : "-",
      {
        id: "colorCategories",
        header: "Color Categories",
        cell: ({ getValue }) => getValue(),
        enableSorting: true,
      }
    ),

    columnHelper.accessor("color", {
      header: "Color",
      cell: (info) => info.getValue(),
    }),

    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <>
          <Button
            onClick={() => {
              setShow(true);
              setItemToEdit(row.original);
            }}
            size="sm"
          >
            <FaEdit size="0.8rem" />
          </Button>

          <Button
            onClick={() => {
              setShowDetails(true);
              setItemToEdit(row.original);
            }}
            className={style.viewDetailsBtn}
            size="sm"
          >
            <FaEye size="0.8rem" />
          </Button>
        </>
      ),
    }),
  ];

  const handleClose = () => {
    if (!missingImage) {
      setShow(false);
      setItemToEdit(null);
    } else {
      Alert.error("Please submit a balloon image");
    }
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setItemToEdit(null);
  };

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Balloons</h1>
        </Col>
        <Col></Col>
      </Row>
      <Row
        className={`gap-3 ${style.body} align-content-center justify-content-center`}
      >
        <Row>
          <Col style={{ display: "flex", justifyContent: "start" }}>
            <Button onClick={() => setShow(true)} variant="secondary" size="sm">
              Upload Balloon
            </Button>
          </Col>
          <Col className="d-flex justify-content-end">
            {!isLoading && (
              <ExportToExcel data={balloonsList} filename="balloons.xlsx" />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!isLoading ? (
              <Datagrid columns={columns} data={balloonsList} filter />
            ) : (
              <Col style={{ textAlign: "center" }}>
                <Spinner />
              </Col>
            )}
          </Col>
        </Row>
      </Row>
      <ModalWindow
        size="lg"
        fullscreen={screenSize < 992 && true}
        show={show}
        onHide={handleClose}
        closeButton
        title={itemToEdit ? "Edit Balloon" : "Upload new Balloon"}
        body={
          <BalloonsForm
            editItem={itemToEdit}
            setShow={handleClose}
            setMissingImage={setMissingImage}
          />
        }
      />
      <ModalWindow
        size="lg"
        fullscreen={screenSize < 992 && true}
        show={showDetails}
        onHide={handleCloseDetails}
        closeButton
        title={itemToEdit && itemToEdit.name}
        body={
          <BalloonsDetails
            item={itemToEdit}
            setShowDetails={handleCloseDetails}
          />
        }
      />
    </Container>
  );
};

export default Balloons;
