import { useState, useMemo, useCallback } from "react";
import { sizes } from "../index";

const useSizeFilter = (data) => {
  const [sizesSelected, setSizesSelected] = useState(() =>
    Object.fromEntries(
      sizes
        .map((size) => `size${size}`)
        .map((prop) => prop.replace(/\./g, "_"))
        .map((item) => [item, false])
    )
  );

  const handleSizesChange = useCallback((size) => {
    setSizesSelected((prevState) => ({
      ...prevState,
      [size]: !prevState[size],
    }));
  }, []);

  const clearAllSizes = useCallback(() => {
    setSizesSelected(
      Object.fromEntries(
        sizes
          .map((size) => `size${size}`.replace(/\./g, "_"))
          .map((prop) => [prop, false])
      )
    );
  }, []);

  const filteredSizes = useMemo(() => {
    const sizesToInclude = new Set(
      Object.keys(sizesSelected).filter((size) => sizesSelected[size])
    );

    if (sizesToInclude.size === 0) {
      return data;
    }

    return data?.filter((balloon) => {
      const balloonTrueSizes = Object.keys(balloon).filter(
        (key) => key.includes("size") && balloon[key] === true
      );

      return balloonTrueSizes.some((size) => sizesToInclude.has(size));
    });
  }, [sizesSelected, data]);

  return {
    sizesSelected,
    handleSizesChange,
    clearAllSizes,
    filteredSizes,
  };
};

export default useSizeFilter;
