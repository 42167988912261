import React, { createContext, useContext, useState } from "react";
import { arrayMove } from "@dnd-kit/sortable";

const ColorMatchContext = createContext();

export const useColorMatchContext = () => useContext(ColorMatchContext);

export const ColorMatchProvider = ({ children }) => {
  const [colormatch, setColormatch] = useState([]);
  const [requestQueueCount, setRequestQueueCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const addBalloon = (newBalloon, index = null) => {
    let isDuplicate = false;

    setColormatch((prevArray) => {
      if (prevArray.some((balloon) => balloon.id === newBalloon.id)) {
        isDuplicate = true;
        return prevArray;
      }

      if (index !== null && index >= 0 && index < prevArray.length) {
        return prevArray.map((balloon, i) =>
          i === index ? newBalloon : balloon
        );
      }

      return [...prevArray, newBalloon];
    });

    return isDuplicate;
  };

  const removeBalloon = (balloonColor) => {
    setColormatch((prevArray) =>
      prevArray.filter((balloon) => balloon.id !== balloonColor)
    );
  };

  /* const isBalloonInMatch = (balloonColor) => {
    return colormatch.some((balloon) => balloon.id === balloonColor);
  }; */

  const resetColorMatch = () => {
    setColormatch([]);
  };

  const replaceAtIndex = (fromIndex, toIndex) => {
    setColormatch((prevArray) => arrayMove(prevArray, fromIndex, toIndex));
  };

  return (
    <ColorMatchContext.Provider
      value={{
        colormatch,
        setColormatch,
        addBalloon,
        removeBalloon,
        // isBalloonInMatch,
        resetColorMatch,
        replaceAtIndex,
        requestQueueCount,
        setRequestQueueCount,
        isProcessing,
        setIsProcessing,
      }}
    >
      {children}
    </ColorMatchContext.Provider>
  );
};
