import React, { useCallback, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useAppContext } from "../../context";
import {
  Alert,
  useScreenSize,
  useAddImages,
  useUpdateImageUploadedCounter,
} from "../../utils";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import { app } from "../../firebase";
import style from "./DropZone.module.css";

const storage = getStorage(app);

const DropZone = ({ btn, addDropdown, addTopBarMobile }) => {
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const { images, setImages, user } = useAppContext();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const screenSize = useScreenSize();
  const updateImageUploadedCounter = useUpdateImageUploadedCounter();

  const addImages = useAddImages();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (images.length > 3) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [images]);

  const uploadingImage = (image) => {
    const storageRef = ref(
      storage,
      `imagesUploadedByUser/${user.name}-${user.lastName}_${image.name}`
    );
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_change",
        (snapshot) => {
          setProgress({
            ...snapshot,
            totalBytes: snapshot.totalBytes,
            complete: snapshot.bytesTransferred,
          });
        },
        (error) => {
          console.error("Error al subir la imagen:", error);
          reject(error);
        },
        async () => {
          console.log("Imagen subida correctamente!");
          try {
            const downloadURL = await getDownloadURL(storageRef);
            resolve({ downloadURL, path: storageRef.fullPath });
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 4) {
        Alert.error("Max of 4 images");
        return;
      }

      updateImageUploadedCounter.mutate({
        email: user.email,
        imageCount: acceptedFiles.length,
      });

      if (
        user.email !== process.env.REACT_APP_ADMIN1_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN2_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN3_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN4_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN5_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN6_EMAIL &&
        user.email !== process.env.REACT_APP_ADMIN7_EMAIL
      ) {
        const uploadPromises = acceptedFiles.map((file) =>
          uploadingImage(file)
        );

        acceptedFiles.forEach((file, index) => {
          if (file) {
            setImages((prevImages) => [...prevImages, file]);

            uploadPromises[index]
              .then(({ downloadURL, path }) => {
                const imageToSend = {
                  imageURL: downloadURL,
                  path: path,
                  user: user.email,
                  uploadedAt: serverTimestamp(),
                };
                addImages.mutate(imageToSend);
              })
              .catch((error) => {
                console.error("Error subiendo la imagen:", error);
              });
          }
        });
      } else {
        acceptedFiles.forEach((file) => {
          setImages((prevImages) => [...prevImages, file]);
        });
      }
    },
    [setImages, user]
  );

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            Alert.error(
              "Format not accepted. Accepts .jpg, .jpeg, .png, .webp and .gif file types."
            );
          }
        });
      });

      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  return (
    <Dropzone
      onDrop={handleDrop}
      disabled={images.length > 3 || !user}
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/gif": [],
        "image/webp": [],
      }}
      multiple={true}
    >
      {({ getRootProps, getInputProps }) => (
        <Row
          xs={12}
          {...getRootProps()}
          className={`${
            images.length === 0 && !btn && !addDropdown && style.dropzone
          } ${!addDropdown && "justify-content-center align-items-center"}`}
          style={{
            backgroundColor:
              images.length === 0 && !btn && !addDropdown && "#F8F6FA",
          }}
        >
          <input {...getInputProps()} />
          {btn && (
            <Col xs="auto">
              {btnDisabled ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      {t("Max of 4 images reached")}
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      variant="secondary"
                      size="sm"
                      className={style.actionBtn}
                      disabled={images.length > 3}
                    >
                      <MdOutlineLibraryAdd /> {t("Add image")}
                    </Button>
                  </div>
                </OverlayTrigger>
              ) : (
                !(images.length === 0) && (
                  <div>
                    <Button
                      variant="secondary"
                      size="sm"
                      className={style.actionBtn}
                      disabled={
                        images.length > 3 || images.length === 0 ? true : false
                      }
                    >
                      <MdOutlineLibraryAdd /> {t("Add image")}
                    </Button>
                  </div>
                )
              )}
            </Col>
          )}

          {addDropdown && (
            <Col xs="auto">
              <div className={style.addDropdownDropzone}>
                {images.length > 3
                  ? t("Max of 4 images reached")
                  : images.length > 1 && screenSize <= 960
                  ? t("Add")
                  : images.length > 0
                  ? t("Add another image")
                  : t("Add image")}
              </div>
            </Col>
          )}

          {addTopBarMobile && (
            <Col xs="auto">
              <div>
                {images.length > 3
                  ? t("Max of 4 images reached")
                  : images.length > 0
                  ? t("Add")
                  : t("Add")}
              </div>
            </Col>
          )}

          {!btn && !addDropdown && images.length === 0 && (
            <Col xs="auto">
              <Row className={`${style.helperTxtContainer}`}>
                <Col className={style.helperTxtCol}>
                  <p className={`${style.helperTxtTitle}`}>
                    {screenSize >= 992 ? (
                      <>
                        {t("Upload your image")}
                        <br />
                        {t("Click on your desired color")}
                        <br />
                        {t("Get your balloon match")}
                      </>
                    ) : (
                      <>
                        {t("Upload an image")}
                        <br />
                        {t("to find your balloon match")}
                      </>
                    )}
                  </p>
                  <Button className={`${style.uploadBtn} btn btn-light btn-md`}>
                    {t("uploadImage")}
                  </Button>
                  {i18n.language === "es" ? (
                    <p className={style.mobileHelper}>
                      Para obtener mejores resultados, <br />
                      evite las imágenes borrosas u oscuras.
                    </p>
                  ) : (
                    <p className={style.mobileHelper}>
                      For best results, avoid blurry or dark images.
                    </p>
                  )}
                </Col>
                <Col xs={12}>
                  {/* {screenSize >= 992 &&
                  <p className={style.helperTextSubtitle}>
                    {t("Accepts")} .jpg, .jpeg, .png, .webp and .gif file types
                    (Max. 5MB)
                    <br />
                    {t(
                      "Blurry or dark images may not provide the best results."
                    )}
                    <br />
                    {t("Use high-quality, well-lit images for best results.")}
                  </p>
                } */}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      )}
    </Dropzone>
  );
};

export default DropZone;
