import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GoEye, GoEyeClosed } from "react-icons/go";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Alert,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import axios from "axios";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { app } from "../../firebase";
import { Toaster } from "sonner";
import { NavLinkWithLocale } from "../../components";
import { Alert as AlertSonner } from "../../utils";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { checkUserExistsInFirestore } from "../../utils/auxiliarFunctions";
import style from "../Login/Login.module.css";

const db = getFirestore(app);

const auth = getAuth(app);

const SignUp = (props) => {
  const [error, setError] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [email, setEmail] = useState(props.customerEmail || "");
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [newUser, setNewUser] = useState({
    password: "",
    coincidentPassword: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [continueWithEmail, setContinueWithEmail] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.customerId) {
      handleGetSubscriptionStatus();
    }
  }, [props.customerId]);

  const handleGetSubscriptionStatus = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL_V2}/api/stripe/subscription-status`,
        {
          params: {
            customer_id: props.customerId,
          },
        }
      )
      .then((response) => {
        setSubscriptionStatus(response.data[0].status);
        //console.log('Data de la suscripción: ', response.data);
      })
      .catch((error) => {
        console.log("Error al obtener estado de la suscripción: ", error);
      });
  };

  const handlePasswordChange = (e) => {
    const name = e.target.name;
    if (name === "password") {
      if (e.target.value.length > 5) {
        setNewUser({ ...newUser, password: e.target.value, active: true });
      } else {
        setNewUser({ ...newUser, password: e.target.value, active: false });
      }
    } else if (name === "coincidentPassword") {
      setNewUser({ ...newUser, coincidentPassword: e.target.value });
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSendWelcomeEmail = async (dataEmail) => {
    try {
      /* const response = await axios.post("http://localhost:4242/send-welcome-email", dataEmail, { */
      const response = await axios.post(
        /* "https://vps-3348494-x.dattaweb.com:5455/send-welcome-email", */
        `${process.env.REACT_APP_BACKEND_URL_V2}/api/send-welcome-email`,
        dataEmail,
        {
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 60000, // Timeout de 60 segundos
        }
      );
      //console.log("Respuesta del servidor:", response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const name = e.target.name?.value;
    const lastName = e.target.lastName?.value;
    const company = e.target.company?.value;
    const email = e.target.email?.value;
    const password = e.target.password?.value;

    if (email === "") {
      setLoading(false);
      return AlertSonner.error("You must enter an email");
    }

    if (name === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a name");
    }

    if (lastName === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a last name");
    }

    if (password === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a password");
    }

    if (password && e.target.coincidentPassword) {
      if (e.target.coincidentPassword.value === "") {
        setLoading(false);
        return AlertSonner.error("You must confirm the password");
      }
      if (e.target.coincidentPassword.value !== password) {
        setLoading(false);
        return AlertSonner.error("Passwords must match");
      }
      if (e.target.coincidentPassword.value.length < 6 && password.length < 6) {
        setLoading(false);
        return AlertSonner.error("Password must be at least 6 characters long");
      }
    }

    if (!checkboxChecked) {
      setLoading(false);
      return AlertSonner.error(
        "You must agree Terms and Privacy policy to create an account"
      );
    }

    const nuevoUsuario = {
      email: email,
      name: name,
      lastName: lastName,
      company: company,
      role: "2",
      stripeCheckoutSessionId: props.sessionId || "",
      stripeCustomerId: props.customerId || "",
      stripeSubscriptionStatus: subscriptionStatus || "",
      signupTimestamp: new Date(),
      firstToolVisit: true,
      firstShowLibraryHelpTooltip: true,
      firstShowMatchingMobileHelpTooltip: true,
    };

    //console.log("Usuario a enviar: ", nuevoUsuario);

    try {
      const dataEmail = {
        email: nuevoUsuario.email,
        name: nuevoUsuario.name,
      };
      await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "usuarios", email), nuevoUsuario);
      await signInWithEmailAndPassword(auth, email, password);

      //await handleSendWelcomeEmail(dataEmail);

      // Llamar a handleSendWelcomeEmail de manera independiente para que no corte la ejecución. Sin await
      handleSendWelcomeEmail(dataEmail).catch((error) => {
        console.error(error);
      });

      localStorage.setItem("userLoggedIn", true);
      setError("");
      setUserCreated(true);

      setLoading(false);
      navigate(`/${i18n.language}/colormatch`);
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/email-already-in-use") {
        AlertSonner.error("An account with this email address already exists");
      }
      if (error.code === "auth/missing-password") {
        setError("You must enter a password");
      }
      console.log("Error al crear el usuario: ", error);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCreateUserAndSendWelcomeEmail = async (userEmail, userName) => {
    const nuevoUsuario = {
      email: userEmail,
      name: userName,
      lastName: "",
      company: "",
      role: "2",
      stripeCheckoutSessionId: props.sessionId || "",
      stripeCustomerId: props.customerId || "",
      stripeSubscriptionStatus: subscriptionStatus || "",
      signupTimestamp: new Date(),
      firstToolVisit: true,
      firstShowLibraryHelpTooltip: true,
      firstShowMatchingMobileHelpTooltip: true,
    };

    try {
      const dataEmail = {
        email: nuevoUsuario.email,
        name: nuevoUsuario.name,
      };

      await setDoc(doc(db, "usuarios", userEmail), nuevoUsuario);

      // Llamar a handleSendWelcomeEmail de manera independiente para que no corte la ejecución. Sin await
      handleSendWelcomeEmail(dataEmail).catch((error) => {
        console.error(error);
      });
    } catch (error) {
      setLoading(false);
      console.log("Error al crear el usuario: ", error);
    }
  };

  const handleSignUpWithGoogle = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = `${process.env.REACT_APP_TEST_DOMAIN}/en/createAccount`;
    const scope = "profile email openid";
    const nonce = Math.random().toString(36).substring(2);

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token id_token&scope=${scope}&nonce=${nonce}`;

    window.location.href = authUrl;
  };

  const handleSignUpWithFacebook = () => {
    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const redirectUri = `${process.env.REACT_APP_TEST_DOMAIN}/en/createAccount`;
    const scope = "email,public_profile";
    const state = Math.random().toString(36).substring(2);

    const authUrl = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&response_type=code`;

    window.location.href = authUrl;
  };

  //FACEBOOK
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    let isUserExist;
    let emailFromFb;
    let nameFromFb;

    if (code) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL_V2}/api/facebook-exchange-token`,
          {
            code: code,
            redirectUri: `${process.env.REACT_APP_TEST_DOMAIN}/en/createAccount`,
            login: false,
          }
        )
        .then((response) => {
          const { accessToken, userExist, email, name } = response.data;
          emailFromFb = email;
          nameFromFb = name;

          if (!userExist) {
            isUserExist = false;
          } else {
            isUserExist = true;
          }

          if (!accessToken) {
            throw new Error("No se recibió un accessToken de Facebook");
          }

          if (isUserExist) {
            AlertSonner.error(
              "An account with this email already exists. Please log in"
            );
            return;
          }

          const credential = FacebookAuthProvider.credential(accessToken);
          return signInWithCredential(auth, credential).then((result) =>
            result.user.getIdToken()
          );
        })
        .then((firebaseIdToken) => {
          return axios.post(
            `${process.env.REACT_APP_BACKEND_URL_V2}/api/facebook-exchange-token`,
            {
              idToken: firebaseIdToken,
              redirectUri: `${process.env.REACT_APP_TEST_DOMAIN}/en/createAccount`,
              login: false,
            }
          );
        })
        .then(async () => {
          //console.log(isUserExist);
          if (isUserExist) {
            AlertSonner.error(
              "An account with this email already exists. Please log in."
            );
          } else {
            console.log("entra create");
            await handleCreateUserAndSendWelcomeEmail(emailFromFb, nameFromFb);
            localStorage.setItem("userLoggedIn", true);
            setError("");
            setUserCreated(true);

            setLoading(false);
            navigate(`/${i18n.language}/colormatch`);
          }
        })
        .catch((error) => {
          //console.error('Error al autenticar con Facebook:', error);
          if (!isUserExist) {
            AlertSonner.error(
              "Error authenticating with Facebook. Please try again"
            );
          }
        });
    } else {
      //console.log("No se encontró el código en la redirección (Facebook)");
    }
  }, [navigate]);

  //GOOGLE
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1)); // Para fragmentos (#)
    const idToken = params.get("id_token");

    if (idToken) {
      const credential = GoogleAuthProvider.credential(idToken);

      const decodedToken = JSON.parse(atob(idToken.split(".")[1])); // Decodificar para aobtener mail
      const emailFromGoogle = decodedToken.email;

      checkUserExistsInFirestore(emailFromGoogle)
        .then((exists) => {
          console.log("EXISTS: ", exists);
          if (!exists) {
            signInWithCredential(auth, credential)
              .then((result) => result.user.getIdToken())
              .then((firebaseIdToken) => {
                // Enviar el Firebase ID token al back
                axios
                  .post(
                    `${process.env.REACT_APP_BACKEND_URL_V2}/api/google-exchange-token`,
                    { idToken: firebaseIdToken, login: false }
                  )
                  .then(async (response) => {
                    if (!response.data.login) {
                      await handleCreateUserAndSendWelcomeEmail(
                        response.data.email,
                        response.data.name
                      );
                    }

                    localStorage.setItem("userLoggedIn", true);
                    setError("");
                    setUserCreated(true);

                    setLoading(false);
                    navigate(`/${i18n.language}/colormatch`);
                  })
                  .catch((error) =>
                    console.error(
                      "Error al enviar el ID Token al backend:",
                      error
                    )
                  );
              })
              .catch((error) => {
                //console.error('Error al autenticar con Google:', error);
                AlertSonner.error(
                  "Error authenticating with Google. Please try again"
                );
              });
          } else {
            // El usuario ya existe
            AlertSonner.error(
              "An account with this email already exists. Please log in."
            );
          }
        })
        .catch((error) => {
          console.error("Error checking user existence:", error);
          AlertSonner.error("An error occurred. Please try again later.");
        });
    } else {
      //console.log('No se encontró el token de acceso en la redirección (Google)');
    }
  }, [navigate]);

  const handleContinueWithEmail = () => {
    setContinueWithEmail(true);
  };

  const handleDisableEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Evitar el envío del formulario al presionar enter
      //console.log('Enter presionado');
    }
  };

  return (
    <Container fluid={true}>
      <Row className={style.rowContainer}>
        <Col className={style.colContainer}>
          <h1 className={style.title}>{t("Create an account")}</h1>
          <Card className={style.card}>
            <Card.Body>
              <NavLinkWithLocale to="/login" className={style.register}>
                {t("Already have an account?")}{" "}
                <span className={style.registerSignupTxt}>{t("Log In")}.</span>
              </NavLinkWithLocale>
              <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label className={style.formLabels}>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={props.customerEmail ? props.customerEmail : email}
                    onChange={handleEmailChange}
                    onKeyDown={handleDisableEnter}
                    className={style.formInputs}
                    disabled={props.customerEmail ? true : false}
                  />
                </Form.Group>
                {!continueWithEmail ? (
                  <>
                    <Row className={style.btnRow}>
                      <Button
                        variant="primary"
                        type="button"
                        className={`${style.loginBtn} ${style.continueWithEmailBtn}`}
                        onClick={handleContinueWithEmail}
                      >
                        {t("Continue with email")}
                      </Button>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label className={style.formLabels}>
                            {t("First Name")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            name="name"
                            className={style.formInputs}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label className={style.formLabels}>
                            {t("Last Name")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            name="lastName"
                            className={style.formInputs}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label className={style.formLabels}>
                        {t("Company")} (optional)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Company Name"
                        name="company"
                        className={style.formInputs}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className={style.formLabels}>
                        {t("Password")}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          /* isInvalid={
                            newUser.password.length > 0 &&
                            newUser.password.length < 6
                          } */
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          name="password"
                          value={newUser.password}
                          onChange={handlePasswordChange}
                          className={style.formInputs}
                        />
                        {showPassword ? (
                          <Button
                            className={style.showPswBtn}
                            variant="dark"
                            onClick={handleShowPassword}
                          >
                            <GoEye />
                          </Button>
                        ) : (
                          <Button
                            className={style.showPswBtn}
                            variant="dark"
                            onClick={handleShowPassword}
                          >
                            <GoEyeClosed />
                          </Button>
                        )}
                        {/* <Form.Control.Feedback type="invalid">
                          {t("Minimum 6 characters")}
                        </Form.Control.Feedback> */}
                      </InputGroup>
                    </Form.Group>
                    {/* {newUser.password && ( */}
                    <Form.Group
                      className={`mb-3 ${style.confirmPassword} ${
                        newUser.active && style.confirmPasswordActive
                      }`}
                    >
                      <Form.Label className={style.formLabels}>
                        {t("Confirm password")}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        name="coincidentPassword"
                        value={newUser.coincidentPassword}
                        onChange={handlePasswordChange}
                        /* isValid={newUser.password === newUser.coincidentPassword}
                          isInvalid={
                            newUser.password !== newUser.coincidentPassword
                          } */
                        className={style.formInputs}
                      />
                      {/* <Form.Control.Feedback>
                          {t("Matching passwords")}
                        </Form.Control.Feedback>
                        {newUser.coincidentPassword.length !== 0 && (
                          <Form.Control.Feedback type="invalid">
                            {t("Missmatched passwords")}
                          </Form.Control.Feedback>
                        )} */}
                    </Form.Group>
                    {/* )} */}
                    {error !== "" ? (
                      <span className={style.errorTxt}>{error}</span>
                    ) : null}
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        id="agree-terms"
                        name="agreeTerms"
                        className={style.agreeTermsCheck}
                      >
                        <Form.Check.Input
                          type="checkbox"
                          onChange={handleCheckboxChange}
                          checked={checkboxChecked}
                        />
                        <Form.Check.Label>
                          {i18n.language === "en" ? (
                            <>
                              By creating an account, I agree to Balloon Color
                              Match{" "}
                              <NavLinkWithLocale to="/terms">
                                Terms
                              </NavLinkWithLocale>{" "}
                              and{" "}
                              <NavLinkWithLocale to="/privacyPolicy">
                                Privacy Policy.
                              </NavLinkWithLocale>
                            </>
                          ) : i18n.language === "es" ? (
                            <>
                              Al crear una cuenta, acepto los{" "}
                              <NavLinkWithLocale to="/terms">
                                Términos
                              </NavLinkWithLocale>{" "}
                              y la{" "}
                              <NavLinkWithLocale to="/privacyPolicy">
                                Política de privacidad
                              </NavLinkWithLocale>{" "}
                              de Balloon Color Match.
                            </>
                          ) : null}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className={style.loginBtn}
                    >
                      {t("Sign Up")}{" "}
                      {loading && (
                        <Spinner
                          as="span"
                          size="sm"
                          animation="grow"
                          role="status"
                        />
                      )}
                    </Button>
                  </>
                )}
                <div className="d-flex align-items-center my-3">
                  <div className="flex-grow-1 border-bottom"></div>
                  <span className="mx-3 text-muted">OR</span>
                  <div className="flex-grow-1 border-bottom"></div>
                </div>
                {!props.sessionId && (
                  <Button
                    onClick={handleSignUpWithGoogle}
                    className={`mt-3 ${style.signupWithGoogleBtn}`}
                  >
                    <FcGoogle size={"1.5rem"} />
                    Continue with Google
                  </Button>
                )}

                {/* {!props.sessionId && (
                  <Button
                    onClick={handleSignUpWithFacebook}
                    className={`mt-3 ${style.signupWithGoogleBtn}`}
                  >
                    <FaFacebook size={"1.5rem"} />
                    Continue with Facebook
                  </Button>
                )} */}
              </Form>
              {userCreated ? (
                <Alert key="secondary" variant="dark" className="mt-4">
                  Successfully created user.{" "}
                  <NavLinkWithLocale
                    to="/login"
                    className={style.goToLoginRegisterSuccess}
                  >
                    Go to Log in.
                  </NavLinkWithLocale>
                </Alert>
              ) : null}
            </Card.Body>
          </Card>
          <Toaster richColors position="bottom-center" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
