import React, { useState, useEffect } from "react";
import { app } from "../../firebase";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { useAppContext } from "../../context";
import { Badge } from "react-bootstrap";

const db = getFirestore(app);

const SubscriptionStatus = ({ userId }) => {
  const { user, setUser } = useAppContext();
  const [subscriptionStatus, setSubscriptionStatus] = useState(
    user ? user.stripeSubscriptionStatus : null
  );
  const [name, setName] = useState(user ? user.name : null);
  const [lastName, setLastName] = useState(user ? user.lastName : null);
  const [company, setCompany] = useState(user ? user.company : null);

  useEffect(() => {
    const userDoc = doc(db, "usuarios", userId);
    const unsubscribe = onSnapshot(
      userDoc,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          const newSubscriptionStatus = data.stripeSubscriptionStatus;
          const newName = data.name;
          const newLastName = data.lastName;
          const newCompany = data.company;

          setSubscriptionStatus(newSubscriptionStatus);
          setName(newName);
          setLastName(newLastName);
          setCompany(newCompany);

          setUser((prevUser) => ({
            ...prevUser,
            stripeSubscriptionStatus: newSubscriptionStatus,
            name: newName,
            lastName: newLastName,
            company: newCompany ? newCompany : "",
          }));
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.log("Error getting document:", error);
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, [userId]);

  return (
    <div>
      {subscriptionStatus == "" && (
        <Badge bg="secondary">Basic Membership</Badge>
      )}
      {subscriptionStatus == "trialing" && (
        <Badge bg="warning" text="dark">
          Trial period
        </Badge>
      )}
      {subscriptionStatus == "active" && (
        <Badge bg="success">Pro Membership</Badge>
      )}
      {subscriptionStatus == "canceled" && <Badge bg="danger">Canceled</Badge>}
    </div>
  );
};

export default SubscriptionStatus;