import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Row, Col, Navbar, Container } from "react-bootstrap";
import style from "./Footer.module.css";
import { NavLinkWithLocale } from "../index";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../utils";

const Footer = () => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  return (
    <Navbar className={`${style.footer} py-2`}>
      <Container>
        <Row
          className={`p-0 m-0 align-items-center justify-content-between ${style.container}`}
        >
          <Col xs={8} md={6} xl={5} className={style.firstHalf}>
            <Row className="flex-column flex-md-row mb-2">
              <Col xs="auto">
                <NavLinkWithLocale to="/terms" className={style.links}>
                  {t("Terms")}
                </NavLinkWithLocale>
              </Col>
              <Col xs="auto">
                <NavLinkWithLocale to="/privacyPolicy" className={style.links}>
                  {t("Privacy Policy")}
                </NavLinkWithLocale>
              </Col>
              <Col xs="auto">
                <NavLinkWithLocale to="/contactUs" className={style.links}>
                  {t("Contact Us")}
                </NavLinkWithLocale>
              </Col>
              {/* <LanguageSwitcher className={style.links} drop="up" position="footer" /> */}
            </Row>
          </Col>
          {screenSize > 768 && (
            <Col xs={12} md={4} className={style.copyright}>
              <p className="m-0">
                {t("© 2025 Balloon Color Match by Campogis LLC.")}{" "}
                {t("All rights reserved.")}
              </p>
            </Col>
          )}
          <Col xs={2} className={style.media}>
            <a
              href="https://instagram.com/ballooncolormatch"
              target="_blank"
              rel="noreferrer noopener"
              className="me-2"
            >
              <FaInstagram color="whitesmoke" className={style.mediaIcons} />
            </a>
            <a
              href="https://www.facebook.com/BalloonColorMatch"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebook color="whitesmoke" className={style.mediaIcons} />
            </a>
            {/* <a
                  href="https://www.tiktok.com/@ballooncolormatch"
                  target="_blank"
                  rel="noreferrer noopener"
                  >
                  <FaTiktok color="whitesmoke" className={style.mediaIcons} />
                  </a> */}
          </Col>
          {screenSize < 768 && (
            <Col xs={12} md={3} className={style.copyright}>
              <p>
                {t("© 2025 Balloon Color Match by Campogis LLC.")}{" "}
                {t("All rights reserved.")}
              </p>
            </Col>
          )}
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
