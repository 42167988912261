import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultModal, setDefaultModal] = useState("");
  const [shareMode, setShareMode] = useState("");
  const [createNewPaletteModal, setCreateNewPaletteModal] = useState(false);
  const [loadingColorMatch, setLoadingColorMatch] = useState(null);
  const [hexaColormatch, setHexaColormatch] = useState([]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        images,
        setImages,
        loading,
        setLoading,
        defaultModal,
        setDefaultModal,
        shareMode,
        setShareMode,
        createNewPaletteModal,
        setCreateNewPaletteModal,
        loadingColorMatch,
        setLoadingColorMatch,
        hexaColormatch,
        setHexaColormatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
