import uploadIcon from "../assets/lucide_upload.svg";
import magicIcon from "../assets/mdi_magic.svg";
import detailsIcon from "../assets/clarity_details-line.svg";
import arrowsIcon from "../assets/stash_arrows-switch.svg";
import paletteIcon from "../assets/circum_palette.svg";

export const sizes = [
  2,
  3,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  16,
  17,
  18,
  19,
  23,
  24,
  26,
  30,
  31,
  36,
  40,
  69,
  160,
  260,
  270,
  350,
  360,
  6.1,
  9.1,
  11.1,
  12.1,
  13.1,
  18.1,
  660.1,
  "h10",
  "h12",
  "h15",
]; //6.1 = 6" LINK | DB: 6_1

export const genericFinish = [
  "Standard",
  "Chrome",
  "Neon",
  "Pastel & Matte",
  "Metallic & Pearl",
  "Vintage & Retro",
  "Crystal",
];

export const genericColors = [
  "Aqua & Mint",
  "Blue",
  "Coral",
  "Goldenrod",
  "Green",
  "Grey",
  "Mauve & Fuchsia",
  "Orange",
  "Periwinkle",
  "Pink",
  "Purple",
  "Red",
  "White",
  "Yellow",
  "Black",
  "Clear",
  "Blue C",
  "Copper C",
  "Gold C",
  "Gold M",
  "Green C",
  "Pink C",
  "Purple C",
  "Red C",
  "Rose Gold C",
  "Rose Gold M",
  "Silver C",
  "Silver M",
  "Taupe C",
];

export const categories = [
  "Standard",
  "Pearl",
  "Chrome",
  "Metallic",
  "Mirror",
  "Crystal",
  "Reflex",
  "Matte",
  "Neon",
  "Brilliant",
  "Shiny",
  "Satin",
  "Silk",
  "Pastel",
  "Luster",
  "Pastel Rainbow",
  "Metallic & Pearl",
  "Smoothies",
  "Superglow",
  "Translucid",
  "Macaron",
  "Platinum",
  "Vintage",
];

export const brands = [
  "Qualatex",
  "Tuftex",
  "Kalisan",
  "Sempertex",
  "Gemar",
  "Prima",
  "Balloonia",
  "Ellies",
  "Decomex",
  "Elitex",
  "Brookloon",
  "Globos Payaso",
  "BuLuLu",
].sort();

export const accessoriesCategories = [
  "Foil Shape",
  "Foil Number & Letter",
  "Confetti",
  "Tassels",
  "Backdrop/Panel Color",
  "Floral & Greenery",
];

export const addDropdownOptions = [
  { key: "balloon", label: "Latex Balloons" },
  // { key: "foilShape", label: "Foil Shape Balloons" },
  // { key: "foilNumberLetter", label: "Foil Number & Letter Balloons" },
  { key: "confetti", label: "Confetti" },
  { key: "tassel", label: "Tassels" },
  // { key: "backdropPanel", label: "Backdrop/Panel Colors" },
  // { key: "floralGreenery", label: "Greenery & Florals" },
  { key: "custom", label: "Add your own" },
];

export const colorCategories = [
  { name: "Reds" },
  { name: "Pinks" },
  { name: "Oranges" },
  { name: "Yellows" },
  { name: "Greens" },
  { name: "Blues" },
  { name: "Purples" },
  { name: "Whites" },
  { name: "Blacks" },
  { name: "Greys" },
  { name: "Nudes, Browns & Neutrals" },
];

export const sponsors = [
  /* {
    name: "payaso",
    image: require("../assets/sponsors/payaso-soon.png"),
  }, */
  /* {
    name: "belbal",
    image: require("../assets/sponsors/belbal-soon.png"),
  }, */
  {
    name: "bululu",
    image: require("../assets/sponsors/bululu-logo.png"),
  },
  {
    name: "qualatex",
    image: require("../assets/sponsors/qualatex-logo.png"),
  },
  {
    name: "sempertex",
    image: require("../assets/sponsors/sempertex-logo.png"),
  },
  {
    name: "kalisan",
    image: require("../assets/sponsors/kalisan-logo.png"),
  },
  {
    name: "tuftex",
    image: require("../assets/sponsors/tuftex-logo.png"),
  },
  /* {
    name: "anagram",
    image: require("../assets/sponsors/anagram-logo-soon.png"),
  }, */
  {
    name: "gemar",
    image: require("../assets/sponsors/gemar-logo.png"),
  },
  {
    name: "balloonia",
    image: require("../assets/sponsors/balloonia-logo.png"),
  },
  {
    name: "prima",
    image: require("../assets/sponsors/prima-logo.png"),
  },
  {
    name: "ellies",
    image: require("../assets/sponsors/ellies-logo.png"),
  },
  {
    name: "decomex",
    image: require("../assets/sponsors/decomex-logo.png"),
  },
  {
    name: "brookloon",
    image: require("../assets/sponsors/brookloon-logo.png"),
  },
  {
    name: "elitex",
    image: require("../assets/sponsors/elitex-logo.png"),
  },
];

export const homeCards = [
  {
    icon: uploadIcon,
    title: "UPLOAD IMAGE & PICK COLOR",
    text: (
      <>
        Upload invites, logos or photos <br /> & click on a color.
      </>
    ),
  },
  {
    icon: magicIcon,
    title: "GET CLOSEST MATCH",
    text: "Find the closest balloon to your color!",
  },
  {
    icon: detailsIcon,
    title: "EXPLORE DETAILS",
    text: (
      <>
        Explore brands, balloon color names,
        <br /> and available sizes.
      </>
    ),
  },
  {
    icon: arrowsIcon,
    title: "FIND ALTERNATIVES",
    text: (
      <>
        Find alternative options <br /> in your preferred brands.
      </>
    ),
  },
  {
    icon: paletteIcon,
    title: "BUILD PALETTE",
    text: (
      <>
        Build your balloon palette, <br /> save and share it!
      </>
    ),
  },
];

export const homeCardsEs = [
  {
    icon: uploadIcon,
    title: "CARGA IMAGEN Y ELIGE COLOR",
    text: (
      <>
        Sube invitaciones, logos o fotos <br /> y haz clic en un color.
      </>
    ),
  },
  {
    icon: magicIcon,
    title: "ENCUENTRA EL GLOBO MÁS SIMILAR",
    text: "Descubre el globo que más se asemeja a tu color.",
  },
  {
    icon: detailsIcon,
    title: "EXPLORA DETALLES",
    text: (
      <>
        Descubre marcas, nombres de colores de globos
        <br /> y tamaños disponibles.
      </>
    ),
  },
  {
    icon: arrowsIcon,
    title: "DESCUBRE ALTERNATIVAS",
    text: (
      <>
        Encuentra opciones alternativas <br /> en tus marcas favoritas.
      </>
    ),
  },
  {
    icon: paletteIcon,
    title: "CREA TU PALETA",
    text: (
      <>
        Crea tu paleta de globos, <br /> guárdala y compártela.
      </>
    ),
  }
];
