import React from "react";
import { Card, Placeholder } from "react-bootstrap";
import style from "./ColorCardSkeleton.module.css";

const ColorCardSkeleton = () => {
  return (
    <Card
      style={{
        borderRadius: 0,
        border: "none",
        marginBottom: "5px",
        opacity: "0.3"
      }}
    >
      <Placeholder className={style.cardImage} bg="light">
        <Placeholder
          size="lg"
          as={Card.Img}
          bg="secondary"
          className={style.cardImage}
          animation="wave"
        />
      </Placeholder>
      <Card.Body className="px-0 py-0">
        <Placeholder as={Card.Title} animation="wave" className="m-0">
          <Placeholder
            xs={5}
            md={7}
            lg={10}
            className={style.cardTitle}
            size="xs"
          />
        </Placeholder>
        <Placeholder xs={12} bg="light">
          <Placeholder.Button
            variant="secondary"
            animation="wave"
            xs={8}
            className={style.cardBtn}
          />{" "}
          <Placeholder.Button
            variant="secondary"
            animation="wave"
            xs={3}
            className={style.cardBtn}
          />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default ColorCardSkeleton;
