import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { useFeaturedBalloons } from "../../utils";
import { SearchColorCard } from "../ColorCards";
import style from "./Featured.module.css";

const Featured = () => {
  const { data: featuredBalloons, isLoading } = useFeaturedBalloons();
  const { t } = useTranslation();

  return (
    <Row className="mt-4 justify-content-center">
      <Col>
        <h2 className={style.featuredTitle}>{t("Popular Searches")}</h2>
      </Col>
      <Row>
        {!isLoading &&
          featuredBalloons.map((balloon) => (
            <Col key={balloon.id} xs={6} md={4} lg={2} className="my-3">
              <SearchColorCard key={balloon.color} balloon={balloon} />
            </Col>
          ))}
      </Row>
    </Row>
  );
};

export default Featured;
