import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { Slider } from "../../../components";
import { useScreenSize } from "../../../utils";
import style from "./TextSlider.module.css";

const TextsSlider = () => {
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const authors = [
    {
      author: "— Nicole G.",
      text: t("review1"),
    },
    {
      author: "— Marianna L.",
      text: t("review2"),
    },
    {
      author: "— Vanessa G.",
      text: t("review3"),
    },
    {
      author: "— Stacy C.",
      text: t("review4"),
    },
    {
      author: "— Hermina T",
      text: t("review5"),
    },
    {
      author: "— Tony J.",
      text: t("review6"),
    },
  ];

  const textsPerGroup = screenSize < 768 ? 1 : screenSize < 992 ? 2 : 3;

  const groupedTexts = [];
  for (let i = 0; i < authors.length; i += textsPerGroup) {
    groupedTexts.push(authors.slice(i, i + textsPerGroup));
  }

  if (
    groupedTexts.length > 0 &&
    groupedTexts[groupedTexts.length - 1].length < textsPerGroup
  ) {
    const lastGroup = groupedTexts[groupedTexts.length - 1];
    const remainingItems = textsPerGroup - lastGroup.length;
    groupedTexts[groupedTexts.length - 1] = [
      ...lastGroup,
      ...authors.slice(0, remainingItems),
    ];
  }

  return (
    <Row className={`m-0 justify-content-center ${style.directQuote}`}>
      <Slider controls={true} indicators={false} interval={5000}>
        {groupedTexts.map((group, groupIndex) => (
          <Carousel.Item key={groupIndex}>
            <Container>
              <Row className="justify-content-center px-3 px-lg-5">
                {group.map((item, index) => (
                  <Col
                    className={style.authorsCol}
                    key={index}
                    xs={12 / textsPerGroup}
                  >
                    <p className={style.authorsText}>{item.text}</p>
                    <p className={style.authorsText}>{item.author}</p>
                  </Col>
                ))}
              </Row>
            </Container>
          </Carousel.Item>
        ))}
      </Slider>
    </Row>
  );
};

export default TextsSlider;
