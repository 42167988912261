import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FiPlusCircle } from "react-icons/fi";
import { MdDone } from "react-icons/md";
import {RiDragMove2Fill} from "react-icons/ri";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useAppContext } from "../../../context";
import { useAddBtnModalState } from "../../index";
import style from "./MobileCommands.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";


const MobileCommandsHexaTool = ({ sortable, setSortable }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { images, hexaColormatch, setCreateNewPaletteModal } = useAppContext();
  const { modalState } = useAddBtnModalState();
  const [showBottomSheet, setShowBottomSheet] = useState({
    add: false,
    save: false,
    more: false,
  });

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, [modalState]);

  const handleCreateNewPalette = (e) => {
    const currentPath = location.pathname.split("/").slice(2).join("/"); // Obtener la ruta sin el prefijo de idioma
    const newPath = `/${i18n.language}/hexamatch`;
    setShowBottomSheet({ more: false });

    if (currentPath !== "hexamatch") {
      navigate(newPath);
    } else {
      setCreateNewPaletteModal(true);
    }
  };

  return (
    <>
      <Navbar fixed="bottom" className={style.mobileCommands}>
        <Nav justify className={style.navRow}>
          <Nav.Item>
            <Button
              onClick={handleCreateNewPalette}
              disabled={hexaColormatch.length === 0 && images.length === 0}
              className={style.mobileCommandBtn}
            >
              <FiPlusCircle size="1.2rem" />
              {t("New")}
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              onClick={() => setSortable(!sortable)}
              disabled={hexaColormatch.length < 2}
              className={style.mobileCommandBtn}
            >
              {sortable ? (
                <MdDone size="1.5rem" />
              ) : (
                <RiDragMove2Fill size="1.5rem" />
              )}
              {sortable ? (
                <span>{t("Done")}</span>
              ) : (
                <span> {t("Reorder")}</span>
              )}
            </Button>
          </Nav.Item>
        </Nav>
      </Navbar>   
    </>
  );
};

export default MobileCommandsHexaTool;