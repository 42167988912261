import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Collapse, Footer, NavLinkWithLocale } from "../../components";
import { PlanSquare } from "./index";
import style from "./Pricing.module.css";
import { TextsSlider } from "./TextsSlider";
import { useAppContext } from "../../context";

const Pricing = () => {
  const { t } = useTranslation();
  const { user } = useAppContext();

  const freeAdvantages = [
    { text: t("Match balloons to your inspiration") },
    { text: t("Find the brand of your preferred colors") },
    { text: t("Check balloon size availability") },
    { text: t("Manually create color palettes") },
    { text: t("Access a large library of latex balloons") },
  ];

  /* const proAdvantages = [
    { text: t("Discover alternate matches") },
    { text: t("Use brand preference filters") },
    { text: t("Save and edit your color palettes") },
    { text: t("Share your color palette with clients") },
    { text: t("Share your color palette with co-workers") },
    { text: t("Download your work") },
  ]; */
  const proAdvantages = [
    { text: t("Set preferred brand preferences") },
    { text: t("Save and edit your color palettes") },
    { text: t("Add your business logo/image") },
    { text: t("Custom imports to your palette") },
    { text: t("Create a shopping list for easy sourcing") },
    { text: t("Access pre-designed palettes") },
  ];

  const paragraphs = [
    {
      title: t("Inspiration Upload"),
      text: t(
        "Upload images to instantly generate matching balloon color palettes."
      ),
    },
    {
      title: t("Customizable Palettes"),
      text: t(
        "Manually add, remove, or replace items in your palette to fit your design needs."
      ),
    },
    {
      title: t("Sourcing Details"),
      text: t(
        "View detailed sourcing information including brands and available sizes."
      ),
    },
    {
      title: t("User-Friendly Interface"),
      text: t(
        "Enjoy a seamless user experience tailored for balloon professionals."
      ),
    },
    {
      title: t("Time-Saving Tool"),
      text: t(
        "Save hours on color matching and sourcing with our automated tools."
      ),
    },
    {
      title: t("Support"),
      text: t(
        "Get answers and guidance whenever you need-our team is here to help."
      ),
    },
    /* {
      title: "Alternative Brands",
      text: "Find and compare alternative balloon brands and sizes for your designs.",
    },
    {
      title: "Accessory Database",
      text: "Access a comprehensive database of popular confetti, tassel, and foil accessories for your balloon designs.",
    }, */
  ];

  const collapses = [
    {
      title: t("faq1Question"),
      text: t("faq1Answer"),
    },
    {
      title: t("faq2Question"),
      text: t("faq2Answer"),
    },
    /* {
      title: t("faq3Question"),
      text: t("faq3Answer"),
    },
    {
      title: t("faq4Question"),
      text: t("faq4Answer"),
    }, */
  ];

  return (
    <Container className="p-0" fluid>
      {!user && (
        <Row
          className={`m-0 py-4 py-md-5 align-items-center justify-content-around ${style.sec1}`}
        >
          <Col lg={5} className={style.pricingIntro}>
            <h1 className={style.sec1Title}>{t("Early Access")}</h1>
            <h5 className={style.sec1Subtitle}>{t("earlyAccessCTA")}</h5>
          </Col>
          <Col xs={12} sm={8} md={6} lg={4} xl={3} className={style.pricingCTA}>
            <NavLinkWithLocale to="/createAccount">
              <Button variant="secondary" size="lg" className={style.signUpBtn}>
                {t("GET STARTED FOR FREE")}
              </Button>
            </NavLinkWithLocale>
            {/* <p className={style.pricingMount}>Then $6.99/month</p> */}
            {/* <p className={style.pricingInfo}>
              Billed after your 7 days free trial as $6.99/month. Renews
              automatically each month and we will notify you if the price
              increases in advance. Cancel at any time.
            </p> */}
            <p className={style.pricingInfo}>{t("No credit card required.")}</p>
          </Col>
        </Row>
      )}
      {/* <Row className={`m-0 py-4 py-md-5 justify-content-center ${style.sec1}`}>
        <Col xs={11} md={7} lg={6}>
          <h1 className={`mb-md-4 ${style.sec1Title}`}>
            {t("Find the best plan for you")}
          </h1>
          <p className={`mt-4 ${style.sec1Subtitle}`}>{t("pricingSubtitle")}</p>
        </Col>
      </Row> */}
      <Row
        className="m-0 px-0 justify-content-center"
        style={{ backgroundColor: "#F8F6FA" }}
      >
        <PlanSquare
          title={t("Basic")}
          price="$0"
          subtitle={t("No credit card required")}
          planInfo={t("freePlanInfo")}
          planBodyTitle={t("Features you’ll love:")}
          advantages={freeAdvantages}
        />
        <PlanSquare
          title="Pro"
          // price="$8"
          subtitle={"Coming Soon" /* t("per month (1 person)") */}
          planInfo={t("proPlanInfo")}
          planBodyTitle={t("Everything in Basic, plus:")}
          advantages={proAdvantages}
        />
      </Row>
      <Row className={`m-0 justify-content-center ${style.sec3}`}>
        <h2 className={`${style.sec3Title}`}>
          {t("Included With Every Plan")}
        </h2>
        {paragraphs.map((p, index) => (
          <Col key={index} xs={10} sm={8} md={5} className="pb-4 px-4">
            <h4 className={style.sec3ParagraphTitle}>{p.title}</h4>
            <p className={`mb-4 ${style.sec3Paragraph}`}>{p.text}</p>
          </Col>
        ))}
      </Row>
      <TextsSlider />
      <Row className={`m-0 justify-content-center ${style.faqSec}`}>
        <h2 className={`mb-5 ${style.sec5Title}`}>
          {t("Frequently Asked Questions")}
        </h2>
        {collapses.map((c, index) => (
          <Col key={index} xs={12} md={10} lg={8} xl={7}>
            <Collapse index={index} title={c.title} text={c.text} />
          </Col>
        ))}
      </Row>
      <Footer />
    </Container>
  );
};

export default Pricing;
