import React from "react";
import { useTranslation } from "react-i18next";
import { FiTrash2, FiSun } from "react-icons/fi";
import { RiImageAddFill } from "react-icons/ri";
import { FaArrowLeft } from "react-icons/fa6";
import { FaArrowRight } from "react-icons/fa6";

import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import { DropZone } from "../index";
import { useAppContext } from "../../context";
import { useScreenSize } from "../../utils";
import style from "./Commands.module.css";

const ToolCommands = ({
  goToPrev,
  goToNext,
  handleDeleteImage,
  handleBrightnessChange,
  index,
  currentBrightness,
}) => {
  const { images } = useAppContext();
  const screenSize = useScreenSize();
  const { t } = useTranslation();

  const popover = (
    <Popover id="popover-brightness">
      <Popover.Body>
        <Row className={`p-0 m-0 justify-content-between align-items-center`}>
          <Col className="p-0" xs={1}>
            <FiSun />
          </Col>
          <Col xs={6} lg={5} className="p-0">
            <Form.Range
              type="range"
              min="0.5"
              max="1.5"
              step="0.01"
              className={style.rangeBar}
              value={currentBrightness()}
              onChange={(e) => handleBrightnessChange(Number(e.target.value))}
            />
          </Col>
          <Col className="p-0" xs="auto">
            <FiSun size="1.5rem" />
          </Col>
          <Col className="p-0" xs="auto">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => handleBrightnessChange(1)}
              className={style.cardsCommandsActionBtn}
            >
              {/* <GrRefresh /> */}
              {t("Reset")}
            </Button>
          </Col>
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <Col
      xs={{ order: 0, span: 12 }}
      md={{ order: 0, span: 6 }}
      lg={{ order: 0, span: 6 }}
      className={`${style.toolCommandsCol} mt-4`}
    >
      <Row
        className={`p-0 m-0 justify-content-center justify-content-lg-start ${style.actionBtnRow}`}
      >
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            variant="dark"
            size="sm"
            className={style.toolCommandsActionBtn}
            disabled={images.length > 3 || images.length === 0 ? true : false}
          >
            <RiImageAddFill size={16} /> <DropZone addDropdown={true} />
          </Button>
        </Col>
        {images.length > 1 && screenSize > 450 && (
          <Col xs="auto" className={`p-0 ${style.prevNextContainer}`}>
            <Button
              variant="dark"
              onClick={goToPrev}
              disabled={index === 0}
              size="sm"
              className={style.toolCommandsActionBtn}
            >
              <FaArrowLeft />
            </Button>

            <Button
              variant="dark"
              onClick={goToNext}
              disabled={images.length - 1 === index}
              size="sm"
              className={style.toolCommandsActionBtn}
            >
              <FaArrowRight />
            </Button>
          </Col>
        )}
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            variant="dark"
            onClick={handleDeleteImage}
            size="sm"
            className={style.toolCommandsActionBtn}
            disabled={images.length > 0 ? false : true}
          >
            <FiTrash2 className="m-0"/>
          </Button>
        </Col>
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
            rootClose
          >
            <Button
              variant="dark"
              size="sm"
              className={style.toolCommandsActionBtn}
              disabled={images.length > 0 ? false : true}
            >
              <FiSun /> {t("Brightness")}
            </Button>
          </OverlayTrigger>
        </Col>
        {/* <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <div className={style.colorCircle} style={{backgroundColor: selectedColorContext}}></div>
        </Col> */}
        {/* <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            className={`${style.actionBtn} ${sortable && style.blackBtn}`}
            onClick={() => setSortable(!sortable)}
            variant="secondary"
            disabled={colormatch.length > 1 ? false : true}
          >
            {sortable ? null : <RiDragMove2Fill />}
            {sortable ? <span>{t("Done")}</span> : <span> {t("Reorder")}</span>}
          </Button>
        </Col> */}
      </Row>
    </Col>
  );
};

export default ToolCommands;
