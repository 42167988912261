import React from "react";
import { Container } from "react-bootstrap";
import { sponsors } from "../../utils";
import style from "./Scroller.module.css";

const Scroller = () => {
  const duplicatedLogos = [...sponsors, ...sponsors];

  return (
    <Container className={`my-4 ${style.carouselContainer}`}>
      <div className={style.carouselContent}>
        {duplicatedLogos.map((logo, idx) => (
          <div key={idx} className={style.carouselItem}>
            <img
              className="d-block w-100"
              src={logo.image}
              alt={`Logo ${idx}`}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Scroller;
