import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import style from "./Commands.module.css";
import { useTranslation } from "react-i18next";
import { RiDragMove2Fill } from "react-icons/ri";
import { useAppContext } from "../../context";

const CardsCommandsHexaTool = ({ sortable, setSortable }) => {
  const { hexaColormatch } = useAppContext();
  const { t } = useTranslation();

  return (
    <Col
      lg={{ order: 0, span: 6 }}
      md={{ order: 0, span: 6 }}
      className={`mt-4 ${style.cardsCommandsContainer}`}
    >
      <Row className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}>
        <Col xs="auto" className={`p-0 ${style.actionBtnColumn}`}>
          <Button
            className={`${style.cardsCommandsActionBtn} ${sortable && style.blackBtn}`}
            onClick={() => setSortable(!sortable)}
            variant="secondary"
            disabled={hexaColormatch.length > 1 ? false : true}
          >
            {sortable ? null : <RiDragMove2Fill />}
            {sortable ? <span>{t("Done")}</span> : <span> {t("Reorder")}</span>}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default CardsCommandsHexaTool;
