import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const fetchFeaturedBalloons = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL_V2}/api/featured/`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching featured balloons:", error);
    throw error; // Lanza el error para que React Query lo maneje
  }
};

export const useFeaturedBalloons = () => {
  return useQuery("featuredBalloons", fetchFeaturedBalloons);
};

const updateFeaturedBalloons = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL_V2}/api/featured/`,
      { balloonsId: data },
      { headers: { "Content-Type": "application/json" } }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating featured balloons:", error);
    throw error;
  }
};

export const useUpdateFeaturedBalloons = () => {
  const queryClient = useQueryClient();
  return useMutation(updateFeaturedBalloons, {
    onSuccess: () => {
      queryClient.invalidateQueries("featuredBalloons");
    },
  });
};
