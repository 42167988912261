import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form } from "react-bootstrap";
import { DropdownBtn } from "../index";
import { sizes } from "../../utils";
import style from "./Filters.module.css";

const FilterBySize = ({
  sizesSelected,
  handleChange,
  clearAll,
  variant,
  customClass,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { t } = useTranslation();

  const handleApplyFilters = () => {
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <DropdownBtn
      title={t("Filter by Size")}
      variant={variant}
      filterDropdownBtn={true}
      showDropdown={showDropdown}
      handleApplyFilters={handleApplyFilters}
      toggleDropdown={toggleDropdown}
      customClass={customClass}
    >
      <Dropdown.Item
        style={{ textDecoration: "underline", paddingBottom: "8px" }}
        onClick={clearAll}
      >
        Clear All
      </Dropdown.Item>
      {sizes.map((size, index) => {
        const transformedSize = size.toString().replace(/\./g, "_");

        return (
          <Dropdown.Item
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(`size${transformedSize}`);
            }}
          >
            <Form.Check
              type="checkbox"
              checked={sizesSelected[`size${transformedSize}`]}
              name={`size${size}`}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(`size${transformedSize}`);
              }}
              onChange={() => {}}
              label={
                transformedSize.includes("_1")
                  ? `${transformedSize.split("_")[0]}" LINK`
                  : size > 100
                  ? size
                  : size.toString().includes("h")
                  ? size
                  : `${size}"`
              }
            />
          </Dropdown.Item>
        );
      })}
      <div className="d-flex justify-content-end px-2">
        <Button
          className={`px-4 ${style.applyFiltersBtn}`}
          onClick={handleApplyFilters}
        >
          Apply
        </Button>
      </div>
    </DropdownBtn>
  );
};

export default FilterBySize;
