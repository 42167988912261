import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SignUp } from "../index";
import axios from "axios";
//import { getAuth, getRedirectResult, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { useAppContext } from "../../context";
import { useUpdateUser } from "../../utils";
import { useTranslation } from "react-i18next";
//import { app } from "../../firebase";

const PaymentSuccess = () => {
  const {user, setUser} = useAppContext();
  const updateUser = useUpdateUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [sessionId, setSessionId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  //const [subscriptionStatus, setSubscriptionStatus] = useState("");

  const handleGetSubscriptionStatus = async (customerId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL_V2}/api/stripe/subscription-status`, {
        params: { customer_id: customerId },
      });
      return response.data[0].status;
    } catch (error) {
      console.log("Error al obtener estado de la suscripción: ", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const sessionIdParam = params.get("session_id");
        setSessionId(sessionIdParam);
  
        if (!sessionIdParam) return;
  
        const sessionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL_V2}/api/stripe/verify-session`, {
          params: { session_id: sessionIdParam },
        });
  
        const customerId = sessionResponse.data.customer;
  
        if (user) {
          const subscriptionStatus = await handleGetSubscriptionStatus(customerId);
  
          const updatedUser = {
            stripeCustomerId: customerId,
            stripeCheckoutSessionId: sessionIdParam,
            stripeSubscriptionStatus: subscriptionStatus,
          };
  
          updateUser.mutate({
            userId: user.email,
            user: updatedUser,
          });
  
          setUser((prevUser) => ({
            ...prevUser,
            ...updatedUser,
          }));
  
          navigate(`/${i18n.language}/colormatch`);
        } else {
          // Maneja el caso de usuario no autenticado
          setCustomerId(customerId);
          setCustomerEmail(sessionResponse.data.customer_details.email);
          setCustomerName(sessionResponse.data.customer_details.name);
        }
      } catch (error) {
        console.log("Error al procesar los datos de la sesión: ", error);
      }
    };
  
    fetchSessionData();
  }, [location, sessionId, user]);

  return (
    <>
      {!user &&
        <SignUp
          sessionId={sessionId}
          customerId={customerId}
          customerEmail={customerEmail}
          customerName={customerName}
        />
      }
    </>
  );
};

export default PaymentSuccess;
