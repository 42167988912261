import { useState, useMemo, useCallback } from "react";
import { genericFinish } from "../index";

const useFilterByCategory = (data) => {
  const [categoriesSelected, setCategoriesSelected] = useState(() =>
    Object.fromEntries(genericFinish.map((category) => [category, false]))
  );

  const handleCategoryChange = useCallback((category) => {
    setCategoriesSelected((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  }, []);

  const clearAllCategories = useCallback(() => {
    setCategoriesSelected(
      Object.fromEntries(genericFinish.map((category) => [category, false]))
    );
  }, []);

  const filteredCategories = useMemo(() => {
    const categoriesToInclude = new Set(
      Object.keys(categoriesSelected).filter(
        (category) => categoriesSelected[category]
      )
    );

    if (categoriesToInclude.size === 0) {
      return data;
    }

    return data?.filter((balloon) => {
      return categoriesToInclude.has(balloon.genericFinish);
    });
  }, [categoriesSelected, data]);

  return {
    categoriesSelected,
    handleCategoryChange,
    clearAllCategories,
    filteredCategories,
  };
};

export default useFilterByCategory;
