import React from "react";
import { Form } from "react-bootstrap";

const Select = ({
  label,
  name,
  onChange,
  value,
  options,
  feedback,
  ...rest
}) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Select onChange={onChange} value={value} name={name} {...rest}>
        <option value="none">Select...</option>
        {options.map((value, index) => (
          <option value={value} key={index}>
            {value}
          </option>
        ))}
      </Form.Select>
      {feedback && (
        <Form.Control.Feedback type={feedback.type}>
          {feedback.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default Select;
