import { toast } from "sonner";

const Alert = {
  success: (description) => toast.success(description),
  error: (description) => toast.error(description),
  info: (description) => toast.info(description),
  // warning: (description) => toast.warning(description),
};

export default Alert;
