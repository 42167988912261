import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, Offcanvas } from "react-bootstrap";
import { LanguageSwitcher, NavLinkWithLocale } from "../index";
import style from "./HeaderBar.module.css";

const HamburguerMenu = ({
  user,
  handleChangeLocation,
  handleLogOut,
  handleNavLinkClick,
  actualLocation,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Offcanvas.Body>
      <Nav>
        {user ? (
          <>
            <Nav.Link
              className={`${style.mobileLinks}`}
              onClick={handleChangeLocation}
            >
              {actualLocation === `/${i18n.language}/colormatch`
                ? t("Create New Palette")
                : t("Workspace")}
            </Nav.Link>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile/palettes"
            >
              {t("Saved palettes")}
            </NavLinkWithLocale>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile"
            >
              {t("Account settings")}
            </NavLinkWithLocale>
          </>
        ) : (
          <>
            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/createAccount"
            >
              {t("Sign Up")}
            </NavLinkWithLocale>

            <NavLinkWithLocale
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/login"
            >
              {t("Log In")}
            </NavLinkWithLocale>
          </>
        )}
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/product"
        >
          {t("Plans & Pricing")}
        </NavLinkWithLocale>
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/search"
        >
          {t("Search Balloons")}
        </NavLinkWithLocale>
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/"
        >
          {t("Home")}
        </NavLinkWithLocale>
        {user && user.role === "1" && (
          <NavLinkWithLocale
            onClick={handleNavLinkClick}
            className={`${style.mobileLinks}`}
            to="/dashboard"
          >
            Dashboard
          </NavLinkWithLocale>
        )}
        <LanguageSwitcher mobile={true} position="mobile" />
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/terms"
        >
          {t("Terms")}
        </NavLinkWithLocale>
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/privacyPolicy"
        >
          {t("Privacy Policy")}
        </NavLinkWithLocale>
        <NavLinkWithLocale
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/contactUs"
        >
          {t("Contact Us")}
        </NavLinkWithLocale>
        <hr />
        {user && (
          <NavLinkWithLocale
            onClick={handleLogOut}
            className={`${style.mobileLinks}`}
          >
            {t("Log out")}
          </NavLinkWithLocale>
        )}
      </Nav>
    </Offcanvas.Body>
  );
};

export default HamburguerMenu;