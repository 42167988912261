import React from "react";
import { Row, Col } from "react-bootstrap";
import { CanvasWithZoomHexaTool } from "../index";
import style from "./ColorPicker.module.css";

const ColorPickerHexaTool = ({
  brightness,
  index,
  onSelect,
  selectedColor,
  setSelectedColor,
}) => {

  return (
    <Row className={style.imageContainer}>
      <Col>
        <CanvasWithZoomHexaTool
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          brightness={brightness}
          index={index}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
};

export default ColorPickerHexaTool;
