import axios from "axios";
import { useMutation } from "react-query";
import { useColorMatchContext } from "../../context";

const requestQueue = [];

const getColorMatch = async ({ elementSelected, userPro, hsvFilter }) => {
  const response = await axios.get(
    `${process.env.REACT_APP_COLORMATCHFN_URL_V2_0}`,
    {
      params: { elementSelected, matchWithNearest: true, userPro, hsvFilter },
    }
  );

  const { closerColor } = response.data;
  if (closerColor != null) {
    return response.data;
  } else {
    throw new Error("No matches found");
  }
};

const processQueue = async (
  mutateAsync,
  setRequestQueueCount,
  isProcessing,
  setIsProcessing
) => {
  if (isProcessing) return;
  setIsProcessing(true);

  setRequestQueueCount(requestQueue.length); // 🔥 Actualizar contador antes de procesar

  while (requestQueue.length > 0) {
    const { request, onSuccess, onError } = requestQueue.shift();
    setRequestQueueCount(requestQueue.length); // 🔥 Actualizar contador tras remover un request

    try {
      const data = await mutateAsync(request);
      onSuccess?.(data);
    } catch (error) {
      onError?.(error);
    }
  }

  setRequestQueueCount(requestQueue.length); // 🔥 Asegurar que termine en 0
  setIsProcessing(false);
};

export const useColorMatch = () => {
  const mutation = useMutation(getColorMatch);
  const { setRequestQueueCount, isProcessing, setIsProcessing } =
    useColorMatchContext();

  const enqueueRequest = (request, { onSuccess, onError }) => {
    requestQueue.push({ request, onSuccess, onError });
    setRequestQueueCount(requestQueue.length); // 🔥 Actualizar el contador cuando agregamos un request
    processQueue(
      mutation.mutateAsync,
      setRequestQueueCount,
      isProcessing,
      setIsProcessing
    );
  };

  return {
    ...mutation,
    mutate: enqueueRequest,
  };
};
