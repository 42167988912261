import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context";
import { ColorCardHexaTool, DndHexaMatch } from "../index";
import { useScreenSize } from "../../utils";
import style from "./CardsContainer.module.css";

const CardsContainerHexaTool = ({ sortable, setSelectedColor }) => {
  const { t } = useTranslation();
  const screenSize = useScreenSize();
  const { loadingColorMatch, hexaColormatch, setHexaColormatch } = useAppContext();
  const [modal, setModal] = useState({
    show: false,
    color: {},
  });

  // seteo el ultimo elemento del array como login para mostrar el spinner
  const itemsToRender =  loadingColorMatch
  ? [...hexaColormatch, "loading"]
  :  hexaColormatch;

  return (
    <>
      <Row
        id="cardsContainer"
        xs={3}
        md={3}
        lg={4}
        xl={4}
        className={`row-gap-4 ${screenSize <= 768 && "mx-sm-4"}`}
      >
        {sortable ? (
          <DndHexaMatch data={hexaColormatch} setData={setHexaColormatch}>
            {hexaColormatch.map((color, index) => (
              <ColorCardHexaTool
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
              />
            ))}
          </DndHexaMatch>
        ) : (
          itemsToRender.map((color, index) => (
            color === "loading" ?
              <div className="d-flex justify-content-center mt-5">
                <Spinner animation="grow" key={index} />
              </div>
            :    
              <ColorCardHexaTool
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
                setSelectedColor={setSelectedColor}
              />
          ))
        )}
      </Row>
      {screenSize <= 992 && (
        <Row>
          <Col className="justify-content-end d-flex p-4">
            <p className={style.versionTxt}>
              Beta version 1.1.10. {t("versionTxt")}
            </p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CardsContainerHexaTool;
