import React from "react";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

const Dnd = ({
  children,
  sortableItems,
  handleDragEnd,
  handleDragStart,
  strategy,
}) => {
  // Inicializa los sensores
  const mouseSensor = useSensor(MouseSensor);

  const touchSensor = useSensor(TouchSensor);

  // Combina los sensores
  const sensors = useSensors(mouseSensor, touchSensor);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={sortableItems} strategy={strategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default Dnd;
