import React from "react";
import { Accordion } from "react-bootstrap";
import style from "./Collapse.module.css";

const Collapse = ({ index, title, text }) => {
  return (
    <Accordion className={style.collapse}>
      <Accordion.Item eventKey={index} className={style.collapseItem}>
        <Accordion.Header className={style.collapseHeader}>
          {title}
        </Accordion.Header>
        <Accordion.Body>{text}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Collapse;
