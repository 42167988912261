import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  updateDoc,
  getFirestore,
  doc,
  addDoc,
  collection,
  getDocs,
  where,
  query,
  deleteDoc,
} from "firebase/firestore";

import { app } from "../../firebase";

const db = getFirestore(app);

const fetchSearchedTerms = async () => {
  const querySnapshot = await getDocs(collection(db, "searchedTerms"));
  return querySnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
    };
  });
};

export const useSearchedTerms = () => {
  return useQuery("searchedTerms", fetchSearchedTerms);
};

const addSearchedTerm = async (term) => {
  await addDoc(collection(db, "searchedTerms"), term);
};

export const useAddSearchedTerm = () => {
  const queryClient = useQueryClient();
  return useMutation(addSearchedTerm, {
    onSuccess: () => {
      queryClient.invalidateQueries("searchedTerms");
    },
  });
};

const deleteSearchedTerms = async ({ user, searchedTerm }) => {
  const q = query(
    collection(db, "searchedTerms"),
    where("user", "==", user),
    where("term", "==", searchedTerm)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0];
    await deleteDoc(doc(db, "searchedTerms", docSnapshot.id));
  } else {
    console.log("Doc not found");
  }
};

export const useDeleteSearchedTerm = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteSearchedTerms, {
    onSuccess: () => {
      queryClient.invalidateQueries("searchedTerms");
    },
    onError: (error) => {
      console.error("Error deleting term:", error);
    },
  });
};