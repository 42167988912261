import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import { paragraphsEn } from "./termsEn";
import { paragraphsEs } from "./termsEs";
import { useTranslation } from "react-i18next";
import style from "./Terms.module.css";

const Terms = () => {
  const { i18n } = useTranslation();

  return (
    <>
      <Container className="my-3">
        {i18n.language === "en"
          ? paragraphsEn.map((p, index) => (
              <Row className="p-0 m-0" key={index}>
                <Col>
                  {p.title && <p className={style.termsTitle}>{p.title}</p>}
                  {p.text.split("\n").map((line, i) => (
                    <p key={i} className={style.termsText}>
                      {line}
                    </p>
                  ))}
                </Col>
              </Row>
            ))
          : paragraphsEs.map((p, index) => (
              <Row className="p-0 m-0" key={index}>
                <Col>
                  {p.title && <p className={style.termsTitle}>{p.title}</p>}
                  {p.text.split("\n").map((line, i) => (
                    <p key={i} className={style.termsText}>
                      {line}
                    </p>
                  ))}
                </Col>
              </Row>
            ))}
      </Container>
      <Footer />
    </>
  );
};

export default Terms;
