import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { AccessoryDetails, AccessoriesForm } from "./index";
import { Alert, useAccessories, useScreenSize } from "../../../utils";
import { Datagrid, ModalWindow } from "../../../components";
import style from "../Dashboard.module.css";

const Accessories = () => {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(false);
  const [missingImage, setMissingImage] = useState(false);
  // const [imagesLoading, setImagesLoading] = useState({});
  const screenSize = useScreenSize();
  const { data: accessoriesList, isLoading, isFetching } = useAccessories();

  // const handleImageLoad = (id) => {
  //   setImagesLoading((prev) => ({ ...prev, [id]: true }));
  // };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "image",
      header: "Image",
      cell: ({ row }) => (
        <>
          {/* {!imagesLoading[row.original.id] && (
            <Spinner animation="grow" size="sm" />
          )} */}
          <Image
            style={{
              width: "2rem",
              // display: imagesLoading[row.original.id] ? "block" : "none",
            }}
            src={
              row.original.image
                ? row.original.image[0].imageURL
                : row.original.imageURL
            }
            // onLoad={() => handleImageLoad(row.original.id)}
            rounded
          />
        </>
      ),
    }),

    columnHelper.accessor("name", {
      header: "Name",
      cell: ({ getValue }) =>
        !isFetching ? (
          getValue()
        ) : (
          <Spinner as="span" size="sm" animation="grow" />
        ),
    }),

    columnHelper.accessor("category", {
      header: "Category",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor(
      (row) => (row.colorCategories ? row.colorCategories.join(", ") : "-"),
      {
        id: "colorCategories",
        header: "Color Categories",
        cell: ({ getValue }) => getValue(),
      }
    ),

    columnHelper.accessor("hexColor", {
      header: "Hex Code",
      cell: (info) =>
        info.getValue() ? `#${info.getValue().replace("#", "")}` : "-",
    }),

    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <>
          <Button
            onClick={() => {
              setShow(true);
              setItemToEdit(row.original);
            }}
            size="sm"
          >
            <FaEdit size="0.8rem" />
          </Button>

          <Button
            onClick={() => {
              setShowDetails(true);
              setItemToEdit(row.original);
            }}
            className={style.viewDetailsBtn}
            size="sm"
          >
            <FaEye size="0.8rem" />
          </Button>
        </>
      ),
    }),
  ];

  const handleClose = () => {
    if (!missingImage) {
      setShow(false);
      setItemToEdit(null);
    } else {
      Alert.error("Please submit a balloon image");
    }
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setItemToEdit(null);
  };

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Accessories</h1>
        </Col>
        <Col></Col>
      </Row>
      <Row
        className={`gap-3 ${style.body} align-content-center justify-content-center`}
      >
        <Row>
          <Col style={{ display: "flex", justifyContent: "start" }}>
            <Button onClick={() => setShow(true)} variant="secondary" size="sm">
              Upload Accessory
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {!isLoading ? (
              <Datagrid columns={columns} data={accessoriesList} filter />
            ) : (
              <Col style={{ textAlign: "center" }}>
                <Spinner />
              </Col>
            )}
          </Col>
        </Row>
      </Row>
      <ModalWindow
        show={show}
        onHide={handleClose}
        size="lg"
        fullscreen={screenSize < 992 && true}
        closeButton
        centered
        title={itemToEdit ? "Edit Accessory" : "Upload new Accessory"}
        body={
          <AccessoriesForm
            editItem={itemToEdit}
            setShow={handleClose}
            setMissingImage={setMissingImage}
          />
        }
      />

      <ModalWindow
        size="lg"
        fullscreen={screenSize < 992 && true}
        show={showDetails}
        onHide={handleCloseDetails}
        closeButton
        title={itemToEdit && itemToEdit.name}
        body={
          <AccessoryDetails
            item={itemToEdit}
            setShowDetails={handleCloseDetails}
          />
        }
      />
    </Container>
  );
};

export default Accessories;
