import React from "react";
import { Container } from "react-bootstrap";
import { MatchingHexaTool } from "./index";

const HexaTool = () => {
  return (
    <Container fluid className="p-0 m-0 mb-3">
      <MatchingHexaTool />
      {/* <Toaster richColors position="bottom-center" closeButton /> */}
    </Container>
  );
};

export default HexaTool;