import React from "react";
import { Dropdown } from "react-bootstrap";
import style from "./DropdownBtn.module.css";
import { FaChevronDown } from "react-icons/fa";

const DropdownBtn = ({
  variant,
  id,
  title,
  disabled,
  children,
  drop,
  customClass,
  align,
  filterDropdownBtn,
  showDropdown,
  handleApplyFilters,
  toggleDropdown,
}) => {
  return (
    <Dropdown
      drop={drop}
      align={align}
      show={filterDropdownBtn ? showDropdown : undefined}
      onToggle={filterDropdownBtn ? toggleDropdown : undefined}
    >
      <Dropdown.Toggle
        id={id}
        variant={variant}
        disabled={disabled}
        className={`${customClass} no-default-icon`}
      >
        {title}
        <FaChevronDown />
      </Dropdown.Toggle>
      <Dropdown.Menu className={style.dropdownMenu}>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownBtn;
