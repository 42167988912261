import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import {
  Alert,
  sizes,
  useBalloonsV2,
  useFeaturedBalloons,
  useUpdateFeaturedBalloons,
} from "../../../utils";
import {
  AutoCompleteFilters,
  BalloonOption,
  Datagrid,
} from "../../../components";
import { FaTrash } from "react-icons/fa";
import { Toaster } from "sonner";
import style from "../Dashboard.module.css";
import { IoReorderTwo } from "react-icons/io5";

const FeaturedBalloons = () => {
  const { t } = useTranslation();
  const sizesMemo = useMemo(() => sizes, []);
  const [selected, setSelected] = useState([]);
  const { data: balloonsData, isLoading: loadingBalloons } = useBalloonsV2();
  const { data: featuredBalloons, isLoading } = useFeaturedBalloons();
  const { mutate, isLoading: loadingUpdate } = useUpdateFeaturedBalloons();

  useEffect(() => {
    if (Array.isArray(featuredBalloons)) {
      setSelected(featuredBalloons);
    } else {
      setSelected([]);
    }
  }, [featuredBalloons]);

  const handleDelete = (row) => {
    setSelected((prevState) =>
      prevState.filter((balloon) => balloon.id !== row.id)
    );
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "reorder",
      header: "Reorder",
      cell: ({ isDragging }) => (
        <IoReorderTwo
          size="1.5rem"
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        />
      ),
    }),

    columnHelper.accessor("image", {
      header: "Image",
      cell: ({ row }) => (
        <Image
          style={{ width: "2rem" }}
          src={
            row.original.image
              ? row.original.image[0].imageURL
              : row.original.imageURL
          }
          rounded
        />
      ),
    }),
    columnHelper.accessor("name", {
      header: "Manufacturer's Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("displayName", {
      header: "Display Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("brand", {
      header: "Brand",
      cell: ({ row }) =>
        row.original.combined
          ? `${row.original.outsideBrand}/${row.original.insideBrand}`
          : row.original.brand,
    }),
    columnHelper.accessor("colorCategories", {
      header: "Color Categories",
      cell: ({ row }) =>
        row.original.colorCategories
          ? row.original.colorCategories.join(", ")
          : "-",
    }),
    columnHelper.accessor("color", {
      header: "Color",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Button size="sm" onClick={() => handleDelete(row.original)}>
          <FaTrash />
        </Button>
      ),
    }),
  ];

  const renderBalloonOption = (option) => (
    <BalloonOption option={option} sizes={sizesMemo} />
  );

  const handleSave = () => {
    const balloonsIdsToSend = selected.map((balloon) => balloon.color);
    mutate(balloonsIdsToSend, {
      onSuccess: () => Alert.success("Featured balloons sent successfully"),
    });
  };

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Featured Balloons</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <AutoCompleteFilters
            options={balloonsData}
            isLoading={loadingBalloons}
            placeholder={t("Search...")}
            renderOption={renderBalloonOption}
            selected={selected}
            setSelected={setSelected}
            button={
              <Button onClick={handleSave} /* disabled={!selected.length} */>
                {loadingUpdate ? (
                  <Spinner animation="grow" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            }
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Datagrid
            columns={columns}
            data={selected}
            setData={setSelected}
            isLoading={isLoading}
            isReorderable
          />
        </Col>
      </Row>
      <Toaster richColors position="bottom-center" />
    </Container>
  );
};

export default FeaturedBalloons;
