import React from "react";
import { Button, Col } from "react-bootstrap";
import { TiDelete } from "react-icons/ti";
import style from "../Form.module.css";

const SelectedOption = ({
  id,
  displayName,
  name,
  selected,
  setSelected,
  deleteFilter,
}) => {
  const handleDelete = () => {
    setSelected(selected.filter((option) => option.id !== id));
  };

  const formattedSize = (name) => {
    if (!name.includes("size")) return name;

    let baseSize = name.replace("size", "");

    if (baseSize.includes("_1")) {
      return baseSize.replace("_1", `" LINK`);
    }

    if (baseSize.includes("h")) {
      return baseSize.replace("h", "h ");
    }

    const numericSize = Number(baseSize);
    return numericSize < 100 ? `${numericSize}"` : numericSize;
  };

  return (
    <Col xs="auto" className={style.selectedOption}>
      {displayName && `${displayName} - `}
      {formattedSize(name)}
      <Button
        className={style.deleteBtn}
        onClick={deleteFilter ? () => deleteFilter(name) : handleDelete}
      >
        <TiDelete />
      </Button>
    </Col>
  );
};

export default SelectedOption;
